<template>
  <AppDataListWidgetItemBase
    @status:click="$emit('status:click', $event)"
    @title:click="onTaskNameClick"
    @subTitle:click="onGoToProjectClick"
    :item="task"
    :title="task.name"
    :subTitle="projectName"
    :statusId="statusId"
    statusModel="task"
    :user="task.member"
  />
</template>

<script>
export default {
  props: {
    task: Object,
  },
  computed: {
    statusId() {
      return this.task?.statusId;
    },
    projectName() {
      return this.task?.project?.name;
    },
  },
  methods: {
    onTaskNameClick() {
      this.$emit("taskName:click", { task: this.task });
    },
    onGoToProjectClick() {
      const projectUrl = this.$router.resolve({
        name: this.$routeNames.PROJECT.VIEW,
        params: { projectId: this.task.project.id },
      }).href;
      window.open(projectUrl, "_blank");
    },
  },
};
</script>
