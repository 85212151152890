<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop" class="p-relative">
    <div class="pb-4 d-flex align-center">
      <AppStorageSearchField :rootId="rootId" @search:click="onSearchClick" />
      <AppNumSelected
        class="pl-3"
        @click="clearSelected"
        :selectedCount="selectedStorages.length"
      />
    </div>
    <AppStoragePath
      :path="path"
      :currentFolderId="folderId"
      :isLoading="isLoading || isCreateNewLoading"
      @path:click="onPathClick"
    />

    <AppStorageTable
      v-model="selectedStorages"
      :storages="items"
      :isLoading="isLoading"
      :showBoligmappa="showBoligmappa"
      :folderRouterName="folderRouterName"
      :rootId="rootId"
      @storage:click="onPreviewStorage"
      @menuAction:click="onMenuActionClick"
    />

    <AppEditFolderDialog
      v-model="dialog.folderEdit.active"
      :data="dialog.folderEdit.data"
      @submit:form="afterRename"
    />

    <AppEditFileDialog
      v-model="dialog.fileEdit.active"
      :data="dialog.fileEdit.data"
      @submit:form="afterRename"
    />

    <AppMoveStorageDialog
      v-model="dialog.move.active"
      :selectedStorages="dialog.move.storage"
      :permissionLevel="dialog.move.permissionLevel"
      :parentId="dialog.move.parentId"
      @after:save="afterMove"
    />

    <AppCopyItemsDialog
      v-model="dialog.copy.active"
      :inItems="dialog.copy.storages"
      @submit:form="afterCopy"
      @preview:item="
        (item) => onPreviewStorage({ storage: item, disablePagination: true })
      "
      type="STORAGE"
    />

    <AppLinkFilesDialog
      v-model="dialog.link.active"
      :files="dialog.link.files"
      :parentId="dialog.link.parentId"
    />

    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteConfirmation.active"
      :title="$t('fileManagement.fileExplorer.deleteConfirmation.title')"
      :subtitle="$t('fileManagement.fileExplorer.deleteConfirmation.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="afterDeleteConfirmation"
    />

    <AppBoligmappaFileUploadDialog
      v-if="showBoligmappa"
      v-model="dialog.boligmappaFileUpload.active"
      :boligmappaNumber="boligmappa.boligmappaNumber"
      :projectId="boligmappa.projectId"
      :files="dialog.boligmappaFileUpload.files"
      @created="afterBoligmappaUpload"
    />

    <AppLoaderDialog
      v-model="dialog.creatingSignableDocument.active"
      :title="$t('fileManagement.creatingSignableDocumentDialog.title')"
    />

    <AppFilePreviewDialog
      v-model="fileId"
      :initialActiveMenu="activePreviewMenu"
      :folderId="folderId"
      :disablePagination="dialog.filePreview.disablePagination"
      @dialog:close="onPreviewDialogClose"
    />

    <AppActivityIndicator
      :show="isDownloading"
      :text="$t('fileManagement.common.downloadingFiles')"
    />
    <AppActivityIndicator
      :show="isDeleting"
      :text="$t('fileManagement.common.deletingFiles')"
    />

    <AppStorageMergeFilesMenu :parentId="folderId" />

    <div :class="[isDragging ? 'file-management_drop-zone' : 'd-none']" @drop="drop">
      <div class="drop-zone-overlay">
        <div class="drop-zone-info">
          <div class="drop-zone-info__text">Drop files to upload them</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  data() {
    return {
      selectedStorages: [],
      isDragging: false,
      dialog: {
        folderEdit: {
          active: false,
          data: null,
        },
        fileEdit: {
          active: false,
          data: null,
        },
        move: {
          active: false,
          storage: [],
          permissionLevel: null,
          parentId: null,
        },
        copy: {
          active: false,
          storages: [],
          parentId: null,
        },
        link: {
          active: false,
          files: [],
          parentId: null,
        },
        deleteConfirmation: {
          active: false,
        },
        creatingSignableDocument: {
          active: false,
        },
        filePreview: {
          disablePagination: false,
        },
        boligmappaFileUpload: {
          active: false,
          files: [],
        },
      },
    };
  },
  props: {
    folderId: String,
    rootId: String,
    folderRouterName: String,
  },
  computed: {
    ...mapGetters("storage", {
      isLoading: "isLoading",
      canUpload: "canUpload",
      parentIsValid: "parentIsValid",
    }),
    ...mapState("storage", {
      items: (state) => state.items,
      parent: (state) => state.parent,
      path: (state) => state.path,
      acceptedTypes: (state) => state.file.acceptedTypes,
      isFileUploading: (state) => state.file.isUploading,
      isFolderUploading: (state) => state.folder.isUploading,
      isDownloading: (state) => state.isDownloading,
      isDeleting: (state) => state.isDeleting,
    }),
    ...mapState("storageMergeToPdf", {
      selectedFilesToMerge: (state) => state.selectedFilesToMerge,
    }),
    ...mapState("wopiDiscovery", {
      wopiDiscovery: (state) => state.discovery,
    }),
    ...mapGetters("activeIntegrations", {
      isIntegrationEnabled: "isIntegrationEnabled",
    }),
    ...mapState("project", {
      project: (state) => state.project,
    }),
    isCreateNewLoading() {
      return this.isFileUploading || this.isFolderUploading;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    fileId() {
      return this.$route.query.fileId;
    },
    activePreviewMenu() {
      return this.$route.query.activeMenu;
    },
    hasSelectedFilesToMerge() {
      return this.selectedFilesToMerge.length > 0;
    },
    shouldPreventDestruction() {
      return this.hasSelectedFilesToMerge;
    },
    boligmappa() {
      if (!this.projectId) return {};
      if (!this.project) return {};
      const isBoligmappaEnabled = this.isIntegrationEnabled(this.$constant.BOLIGMAPPA);
      const boligmappaNumber = this.project.boligmappaNumber;
      if (!boligmappaNumber || !isBoligmappaEnabled) return {};
      return {
        projectId: this.projectId,
        boligmappaNumber,
      };
    },
    showBoligmappa() {
      if (!this.boligmappa) return false;
      if (!Object.values(this.boligmappa).length > 0) return false;
      return true;
    },
  },
  watch: {
    folderId() {
      this.getStorage(this.folderId);
    },
  },
  methods: {
    getStorage(id) {
      this.$store.dispatch("storage/getStorage", id);
      this.$store.dispatch("storage/getParent", id);
      this.$store.dispatch("storage/getStoragePath", { id, rootId: this.rootId });
    },
    getAcceptedFileTypes() {
      this.$store.dispatch("storage/getAcceptedFileTypes");
    },
    getWopiDiscovery() {
      this.$store.dispatch("wopiDiscovery/getDiscovery");
    },
    clearSelected() {
      this.selectedStorages = [];
    },
    navigateToFolder(id, rootId = this.rootId) {
      this.clearSelected();
      this.$router.push({
        name: this.folderRouterName,
        params: {
          folderId: id,
          rootId,
        },
      });
    },
    dragover(e) {
      e.preventDefault();
      if (!e.dataTransfer?.types?.includes("Files")) return;
      this.isDragging = true;
    },
    dragleave(e) {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.onFileUpload(e);
      this.isDragging = false;
    },
    navigateToFile({ fileId, activeMenu, openAddRecipients }) {
      const currentQuery = { ...this.$route.query }; // To keep other query params
      currentQuery.fileId = fileId;
      currentQuery.activeMenu = activeMenu;
      currentQuery.openAddRecipients = openAddRecipients;
      this.$router.replace({
        name: this.folderRouterName,
        params: {
          folderId: this.folderId,
          rootId: this.rootId,
        },
        query: currentQuery,
      });
    },
    onPathClick(id) {
      if (id === this.folderId) return this.getStorage(id);
      this.navigateToFolder(id);
    },
    onPreviewStorage({ storage, disablePagination = false }) {
      if (storage.type === this.$constant.FOLDER) {
        this.navigateToFolder(storage.id);
      } else if (storage.type.includes(this.$constant.FILE)) {
        this.dialog.filePreview.disablePagination = disablePagination;
        this.navigateToFile({ fileId: storage.id });
      }
    },
    onSearchClick({ item }) {
      if (item.type === this.$constant.FOLDER && item.id === this.folderId)
        return this.getStorage(item.id);
      this.onPreviewStorage({ storage: item, disablePagination: true });
    },
    onMenuActionClick({ action, storages, context }) {
      if (!storages.length) return;
      const ids = (storages || []).map((s) => s.id);
      const id = ids[0];
      const storage = storages[0];
      switch (action) {
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.PREVIEW: {
          this.navigateToFile({ fileId: id });
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.RENAME: {
          this.onStorageRename(storage);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.DOWNLOAD: {
          this.onDownload(ids);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.MOVE: {
          this.onStorageMove(storages);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.COPY: {
          this.onStorageCopy(storages);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.LINK: {
          this.onStorageLink(storages);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.ADD_TO_MERGE: {
          this.onAddToMerge(storages);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.CREATE_SIGNABLE_DOCUMENT: {
          this.onCreateSignableDocument({ item: storage });
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.REQUEST_READ_CONFIRMATION: {
          this.onRequestReadConfirmation(storage);
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.UPLOAD_TO_BOLIGMAPPA: {
          this.dialog.boligmappaFileUpload.active = true;
          this.dialog.boligmappaFileUpload.files = storages;
          break;
        }
        case this.$constant.STORAGE_CONTEXT_MENU_ACTIONS.DELETE: {
          this.onDelete();
          break;
        }
      }
    },
    onStorageRename(storage) {
      if (storage.type === this.$constant.FOLDER) {
        this.dialog.folderEdit.active = true;
        this.dialog.folderEdit.data = storage;
      } else {
        this.dialog.fileEdit.active = true;
        this.dialog.fileEdit.data = storage;
      }
    },
    onStorageMove(storages) {
      const permissionLevel = storages[0].permissionLevel;
      this.dialog.move.storage = storages;
      this.dialog.move.permissionLevel = permissionLevel;
      this.dialog.move.parentId = this.folderId;
      this.dialog.move.active = true;
    },
    onStorageCopy(storages) {
      this.dialog.copy.storages = storages;
      this.dialog.copy.active = true;
      this.dialog.copy.parentId = this.folderId;
    },
    onStorageLink(storages) {
      this.dialog.link.files = storages;
      this.dialog.link.parentId = this.folderId;
      this.dialog.link.active = true;
    },
    onDownload(ids) {
      this.$store.dispatch("storage/download", ids);
    },
    async onFileUpload(e) {
      if (!this.canUpload) return;
      const parentId = this.folderId;
      const files = this.storageMixins_getFiles(e);
      const { formData } = await this.storageMixins_getFileFormData(files, parentId);
      this.$store.dispatch("storage/uploadFiles", { parentId, formData });
    },
    onDelete() {
      this.dialog.deleteConfirmation.active = true;
    },
    async afterDeleteConfirmation() {
      await this.$store.dispatch("storage/deleteStorageItems", {
        parentId: this.folderId,
        items: this.selectedStorages,
      });
      this.clearSelected();
    },
    onPreviewDialogClose() {
      this.navigateToFile({});
    },
    afterRename(item) {
      switch (item.type) {
        case this.$constant.FOLDER:
          this.$store.dispatch(`storage/updateFolder`, item);
          break;
        case this.$constant.FILE:
          this.$store.dispatch(`storage/updateFile`, item);
          break;
        case this.$constant.FILE_RELATION:
          this.$store.dispatch(`storage/updateFile`, item);
          break;
      }
    },
    afterCopy(items) {
      const parentId = this.dialog.copy.parentId;
      this.$store.dispatch(`storage/copyItems`, {
        body: {
          storages: items,
          parentId,
        },
        parentId,
      });
    },
    afterMove() {
      this.getStorage(this.folderId);
      this.clearSelected();
    },
    onAddToMerge(files) {
      // Combine the selected files and new files
      const filesToMerge = [...this.selectedFilesToMerge, ...files];
      // Filter out duplicates based on the file.id
      const uniqueFilesToMerge = filesToMerge.filter(
        (file, index, self) => index === self.findIndex((f) => f.id === file.id),
      );
      this.$store.commit("storageMergeToPdf/setSelectedFiles", uniqueFilesToMerge);
    },
    afterBoligmappaUpload() {
      this.getStorage(this.folderId);
    },
    async onCreateSignableDocument({ item }) {
      this.dialog.creatingSignableDocument.active = true;
      this.$store
        .dispatch("storage/createSignableDocument", {
          storageId: item.id,
          parentId: item.parentId,
        })
        .then((newStorage) => {
          if (Object.prototype.hasOwnProperty.call(newStorage, "id")) {
            this.navigateToFile({
              fileId: newStorage.id,
              activeMenu: "E_SIGN",
              openAddRecipients: true,
            });
          }
        })
        .finally(() => {
          this.dialog.creatingSignableDocument.active = false;
        });
    },
    onRequestReadConfirmation(storage) {
      this.navigateToFile({
        fileId: storage.id,
        activeMenu: "READ_CONFIRMATION",
        openAddRecipients: true,
      });
    },
    beforeUnload(e) {
      if (this.shouldPreventDestruction) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
  },
  created() {
    addEventListener("beforeunload", this.beforeUnload);
  },
  destroyed() {
    removeEventListener("beforeunload", this.beforeUnload);
  },
  async mounted() {
    await this.$store.dispatch("storage/resetState");
    this.getAcceptedFileTypes();
    this.getStorage(this.folderId);
    if (!this.wopiDiscovery) {
      this.getWopiDiscovery();
    }
  },
};
</script>
