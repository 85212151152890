export default {
  light: {
    primary: {
      lighten4: "#D1E6F6",
      lighten3: "#A7CBED",
      lighten2: "#709BC9",
      lighten1: "#426593",
      base: "#142A4B",
      darken1: "#0E2040",
      darken2: "#0A1836",
      darken3: "#06102B",
      darken4: "#030B24",
    },
    secondary: "#FE9750",
    // accent: {
    //   lighten4: "",
    //   lighten3: "",
    //   lighten2: "",
    //   lighten1: "",
    //   base: "",
    //   darken1: "",
    //   darken2: "",
    //   darken3: "",
    //   darken4: "",
    // },
    success: {
      lighten4: "#D7F9D1",
      lighten3: "#AAF4A6",
      lighten2: "#74E078",
      lighten1: "#4CC15D",
      base: "#1E993B",
      darken1: "#15833A",
      darken2: "#0F6E37",
      darken3: "#095833",
      darken4: "#05492F",
    },
    error: {
      lighten4: "#F9D9D2",
      lighten3: "#F3AEA7",
      lighten2: "#DD7576",
      lighten1: "#BB4D58",
      base: "#8E1F35",
      darken1: "#7A1633",
      darken2: "#660F31",
      darken3: "#52092C",
      darken4: "#440529",
    },
    warning: {
      lighten4: "#FEF7CB",
      lighten3: "#FEED98",
      lighten2: "#FCE065",
      lighten1: "#FAD23E",
      base: "#F7BD00",
      darken1: "#D49D00",
      darken2: "#B17F00",
      darken3: "#8F6300",
      darken4: "#764F00",
    },
    info: {
      lighten4: "#C8FBF6",
      lighten3: "#94F7F4",
      lighten2: "#5CDFE7",
      lighten1: "#33BBCF",
      base: "#008CAF",
      darken1: "#006D96",
      darken2: "#00527D",
      darken3: "#003A65",
      darken4: "#002A53",
    },
    anchor: "#0090DD",
    "primary-link": {
      lighten4: "#CAF8FD",
      lighten3: "#96EBFB",
      lighten2: "#61D4F4",
      lighten1: "#3AB8EA",
      base: "#0090DD",
      darken1: "#006FBE",
      darken2: "#00539F",
      darken3: "#00539F",
      darken4: "#002A6A",
    },
    purple: {
      lighten4: "#CEDFFB",
      lighten3: "#9EBDF7",
      lighten2: "#6B93E7",
      lighten1: "#446DCF",
      base: "#133DAF",
      darken1: "#0D2E96",
      darken2: "#09227D",
      darken3: "#061765",
      darken4: "#031053",
    },
    "dark-green": {
      lighten4: "#C6F8E9",
      lighten3: "#91F1DC",
      lighten2: "#56D7C5",
      lighten1: "#2CB0A9",
      base: "#00787C",
      darken1: "#005E6A",
      darken2: "#004759",
      darken3: "#003347",
      darken4: "#00253B",
    },
    pink: {
      lighten4: "#FFE7E3",
      lighten3: "#FFCAC8",
      lighten2: "#FFADB3",
      lighten1: "#FF99AA",
      base: "#FF779B",
      darken1: "#DB5686",
      darken2: "#B73B74",
      darken3: "#932563",
      darken4: "#7A1657",
    },
    background: "#F4F4F2",
    "ui-background": "#FFFFFF",
    "ui-background-black": "#252628",
    "primary-text": "#121212",
    "primary-border": "#CBD6E2",
    "input-background": "#F5F8FA",
    "action-button": "#EAF0F6",
    disabled: "#D9D9D9",
    subtitle: "#606060",
    // "starter-plan": "#C7E3E4",
    // "grow-plan": "#DFD1EF",
    "pro-plan": "#FEC9A4",
    helper: "#95CACB",
    "select-all": "#dceaf0",
    "primary-super-light": "#F3F4F6",
    "success-super-light": "#e8f5eb",
    "secondary-super-light": "#FFF4ED",
    "error-super-light": "#F3E8EA",
    "info-super-light": "#EAF5F8",
    task: "#C7E3E4",
    project: "#FEC9A4",
    form: "#DFD1EF",
  },
  /* dark: {
        primary: "#9F70E4",
        secondary: "#89CCD6",
        accent: "#794AC9",
        error: "#E68669",
        info: "#90E1EC",
        success: "#64E6C3",
        warning: "#FBDE98",
        background: "#0f0f0f",
        "ui-background": "#272727",
        "primary-link": "#5FA4EE",
        "primary-text": "#72BDC3",
        "primary-border": "#D9E4E8",
      }, */
};
