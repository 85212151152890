<script>
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import QuestionItem from "./QuestionItem.vue";
import { VDivider } from "vuetify/lib";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    item: Object,
    items: Array,
  },
  data() {
    return {
      observer: null,
    };
  },
  methods: {
    genGroup(item, isLast) {
      return this.$createElement("div", [
        this.$createElement(
          "div",
          { class: "text-h6 font-weight-bold py-5" },
          `${item.number} ${item.name}`,
        ),
        this.$createElement("div", this.genHierarchy(item.children, !isLast)),
      ]);
    },
    genHierarchy(items, showLastDivider) {
      const hierarchy = [];
      for (let item of items) {
        const isLast = items.indexOf(item) === items.length - 1;
        if (item.type === this.$constant.GROUP) {
          hierarchy.push(this.genGroup(item, isLast));
        } else {
          const showDivider = showLastDivider || !isLast;
          hierarchy.push(
            this.$createElement("div", { class: "question-item-divider" }, [
              this.$createElement(QuestionItem, {
                props: { item, observer: this.observer },
                on: {
                  "item:taskClick": (e) => this.$emit("item:taskClick", e),
                  "item:imagePreview": (e) => this.$emit("item:imagePreview", e),
                },
              }),
              showDivider && this.$createElement("div", { class: "border-b" }),
            ]),
          );
        }
      }
      return hierarchy;
    },
    onElementObserved(entries) {
      for (let { target, isIntersecting } of entries) {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        const id = target.getAttribute("data-id");
        this.$emit("item:getImages", { id });
      }
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$el,
      threshold: 1.0,
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  render(h) {
    return h("div", [
      h(
        "div",
        this.genHierarchy(
          this.formTemplateBuilderMixins_getHierarchyByParentId(this.items, this.item.id),
        ),
      ),
    ]);
  },
};
</script>
