<template>
  <AppModuleAutocomplete
    v-bind="params"
    v-on="listeners"
    :items="groupedItems"
    :filter="customFilter"
    :titleField="$constant.MODULE_FIELD_CONFIG.USER_AND_CONTACT.TITLE_FIELD"
    :subTitleFields="$constant.MODULE_FIELD_CONFIG.USER_AND_CONTACT.SUB_TITLE_FIELDS"
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"></slot>
    <template v-slot:prepend-inner="{ item }" v-if="userAvatar">
      <AppUserAvatar
        :user="item"
        :truncate="60"
        :itemText="itemText"
        class="pr-4 d-flex"
      />
    </template>
  </AppModuleAutocomplete>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isOnlyContacts: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    labelPaths: {
      type: Array,
      default: () => [],
    },
    userAvatar: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  computed: {
    ...mapGetters("auth", {
      currentDomain: "currentDomain",
    }),
    listeners() {
      return { ...this.$listeners };
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    groupedItems() {
      const noClientText = this.$t("contact.addContact.noClient");
      const ownWorksapceText = this.currentDomain.name;
      const noClientKey = "no_client";
      const ownWorkspaceKey = "own_workspace";

      const grouped = this.items.reduce((acc, item) => {
        // Determine the group based on contact status first, then by client
        let clientId;

        if (!this.isOnlyContacts && !item.isContact) {
          clientId = ownWorkspaceKey; // Group for users who are not contacts
        } else {
          clientId = item.client && item.client.id ? item.client.id : noClientKey; // Use a special key for no client
        }

        if (!acc[clientId]) {
          acc[clientId] = [];
        }
        acc[clientId].push({ ...item, type: clientId });
        return acc;
      }, {});

      const groupsWithHeaders = Object.entries(grouped).map(([clientId, contacts]) => {
        let header;
        if (clientId === noClientKey) {
          header = noClientText;
        } else if (clientId === ownWorkspaceKey) {
          header = ownWorksapceText; // Name for the group of users who are not contacts
        } else {
          header = contacts[0].client ? contacts[0].client.name : "";
        }

        const sortedContacts = contacts.sort((a, b) => {
          const nameA = a.fullName || "";
          const nameB = b.fullName || "";
          return nameA.localeCompare(nameB);
        });

        const headerItem = {
          header,
          type: clientId,
          isHeader: true,
        };

        return [headerItem, ...sortedContacts];
      });

      const sortedGroups = groupsWithHeaders.sort((groupA, groupB) => {
        // Ensure the not-contact group is always first
        if (groupA[0].type === ownWorkspaceKey) return -1;
        if (groupB[0].type === ownWorkspaceKey) return 1;

        // Then handle the no-client group
        if (groupA[0].header === noClientText) return -1;
        if (groupB[0].header === noClientText) return 1;

        // Finally, sort by client name
        return groupA[0].header.localeCompare(groupB[0].header);
      });

      const flattenedAndSorted = sortedGroups.flat();

      //dont display client because items are already grouped by client
      const itemsWithoutClients = flattenedAndSorted.map((item) => {
        delete item.client;
        return item;
      });

      return itemsWithoutClients;
    },
  },
  methods: {
    customFilter(item, queryText) {
      const query = queryText.toLowerCase();

      if (item?.searchStr?.includes(query)) {
        return true;
      }

      if (item.client && item.client.name.toLowerCase().includes(query)) {
        return true;
      }

      // Flatten items for easier searching under headers
      const flattenItems = this.groupedItems.flatMap((group) =>
        group.isHeader ? [] : group,
      );

      // For header items, check if any items under the header match the query
      if (item.hasOwnProperty("header")) {
        const itemsUnderHeader = flattenItems.filter(
          (contact) => contact.type === item.type,
        );
        return itemsUnderHeader.some((contact) => this.customFilter(contact, query));
      }

      // Handle "no client" and "own workspace" headers
      if (!item.client) {
        const noClientText = this.$t("contact.addContact.noClient").toLowerCase();
        const ownWorkspaceText = this.currentDomain.name.toLowerCase();

        // Match against "no client" text if item is of "no client" type
        if (item.type === "no_client" && noClientText.includes(query)) {
          return true;
        }

        // Match against "own workspace" text if item is of "own workspace" type and not filtering only contacts
        if (
          !this.isOnlyContacts &&
          item.type === "own_workspace" &&
          ownWorkspaceText.includes(query)
        ) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>
