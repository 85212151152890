<template>
  <v-dialog
    v-model="localDialog"
    transition="dialog-bottom-transition"
    fullscreen
    persistent
    no-click-animation
  >
    <v-card class="background" :disabled="isConverting">
      <AppCmsProjectTemplatePreviewDialog
        v-model="previewDialiog.active"
        :templateId="previewDialiog.templateId"
      />
      <v-toolbar class="border-b" dense flat fixed height="80">
        <v-toolbar-title>
          {{ $t("templateManagement.form.templatePicker.title") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="localDialog = false">
          <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="main-height d-flex overflow-y-auto">
        <div class="page-container">
          <div class="pb-4 d-flex align-center">
            <div class="d-flex">
              <AppDefaultTextField
                class="mw-250px mr-2"
                :label="$t('common.search')"
                :append-icon="$icon.LIGHT.ACTION.SEARCH"
                :value="filter.search"
                @input="debounceSearch"
                hide-details
                clearable
                autofocus
                background-color="white"
              />

              <AppDefaultAutocomplete
                class="mw-250px mr-2"
                :label="$t('common.category')"
                :value="filter.categoryIds"
                @change="filter.categoryIds = $event"
                :items="categories"
                :loading="isCategoriesLoading"
                background-color="white"
                item-text="name"
                hide-details
                multiple
                clearable
              />
              <AppNumSelected
                :selectedCount="selectedTemplates.length"
                @click="selectedTemplates = []"
              />
            </div>
            <v-spacer />
            <v-btn
              class="mr-2"
              color="primary"
              text
              :href="`mailto:${$constant.CONTACT_EMAIL}`"
              target="_blank"
            >
              {{ $t("templateManagement.form.templatePicker.request") }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              :disabled="selectedTemplates.length <= 0"
              @click="addTemplates"
              :loading="isConverting"
            >
              {{ $t("templateManagement.form.templatePicker.addTemplates") }}
            </v-btn>
          </div>

          <AppDataTable
            :noDataText="$t('common.noTemplates')"
            v-model="selectedTemplates"
            :loading="isLoading"
            :headers="_headers"
            :items="templates"
            show-select
          >
            <template v-slot:item="{ item, isSelected, select }">
              <tr
                class="list_tr"
                :class="item.id ? (isSelected ? 'list_tr--selected' : '') : ''"
                @click="select(!isSelected)"
              >
                <td>
                  <v-simple-checkbox
                    color="primary"
                    :value="isSelected"
                    @input="select($event)"
                    hide-details
                  />
                </td>
                <td class="text-start">
                  <span
                    class="underline-on-hover cursor-pointer"
                    @click="onTemplateNameClick(item)"
                  >
                    {{ item.name }}
                  </span>
                </td>

                <td>
                  <AppColorChip
                    v-if="item.category"
                    :text="
                      $te(`formManagement.category.${item.category.name}`)
                        ? $t(`formManagement.category.${item.category.name}`)
                        : item.category.name
                    "
                    color="info--text"
                  />
                </td>

                <td>
                  {{ new Date(item.updatedAt) | dateFormat("DD.MM.YYYY HH:mm") }}
                </td>
              </tr>
            </template>
          </AppDataTable>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { convertCmsTemplates } from "../../../../services/template/cmsProject/convertTemplates";
import { mapState } from "vuex";

export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      selectedTemplates: [],
      isConverting: false,
      filter: {
        search: null,
        tags: [],
        categoryIds: [],
      },
      searchDebounce: null,
      templateId: null,
      previewDialiog: {
        active: false,
        templateId: null,
      },
    };
  },
  computed: {
    ...mapState("selectableCmsProjectTemplates", {
      templates: (state) => state.templates,
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("projectTemplateCategories", {
      categories: (state) => state.categories,
      isCategoriesLoading: (state) => state.isLoading,
    }),
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          text: this.$options.filters.capitalize(
            this.$t("templateManagement.templateName"),
          ),
          value: "name",
          show: true,
        },
        {
          text: this.$options.filters.capitalize(this.$t("common.category")),
          width: "15%",
          value: "category.name",
          show: true,
        },
        {
          text: this.$t("common.lastUpdated"),
          width: "15%",
          value: "updatedAt",
          show: true,
        },
      ];
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getProjectTemplates(this.filterArgs());
          this.getCategories();
        }
      },
    },
    filter: {
      handler(filter) {
        this.getProjectTemplates(this.filterArgs(filter));
      },
      deep: true,
    },
  },
  methods: {
    getProjectTemplates(args) {
      this.$store.dispatch("selectableCmsProjectTemplates/getTemplates", args);
    },
    getCategories() {
      this.$store.dispatch("projectTemplateCategories/getCategories");
    },
    getIndustryNames(template) {
      if (!Array.isArray(template.tags)) return [];
      return template.tags.filter((t) => t.type === "INDUSTRY").map((t) => t.name);
    },
    onTemplateNameClick(template) {
      this.previewDialiog.templateId = template.id;
      this.previewDialiog.active = true;
    },
    addTemplates() {
      if (this.selectedTemplates.length <= 0) return;
      this.isConverting = true;
      const ids = this.selectedTemplates.map((s) => s.id);
      convertCmsTemplates({ ids })
        .then((templates) => {
          this.$emit("converted", { templates });
          this.localDialog = false;
          this.selectedTemplates = [];
        })
        .finally(() => (this.isConverting = false));
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filter.search = event;
      }, 600);
    },
    filterArgs(args) {
      return {
        ...this.filter,
        ...args,
      };
    },
  },
};
</script>
