import axios from "axios";
import FileSaver from "file-saver";
const apiUrl = process.env.VUE_APP_API_URL;

export async function createActionRequest({ body }) {
  return axios
    .post(`${apiUrl}action-request`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function updateActionRequest({ id, body }) {
  return axios
    .put(`${apiUrl}action-request/${id}`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function remindActionRequest({ id, body }) {
  return axios
    .post(`${apiUrl}action-request/${id}/remind`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function resetActionRequest({ id, body }) {
  return axios
    .post(`${apiUrl}action-request/${id}/reset`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function revokeActionRequest({ id, body }) {
  return axios
    .post(`${apiUrl}action-request/${id}/revoke`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

export async function downloadActionRequest({ id }) {
  return axios
    .get(`${apiUrl}action-request/${id}/download`, {
      responseType: "blob",
    })
    .then((res) => {
      if (!res?.data) {
        throw new Error("No data received");
      }
      let fileName = "";
      const contentDisposition = res.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      FileSaver.saveAs(res.data, fileName);
    })
    .catch((error) => {
      throw new Error(error);
    });
}
