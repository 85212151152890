<script>
import { formTemplateBuilderMixins } from "@/helpers/mixins";
import SectionHeader from "./SectionHeader.vue";
import ItemHierarchy from "./ItemHierarchy.vue";
import { VCard, VForm } from "vuetify/lib";
import { VBtn } from "vuetify/lib";
import { VIcon } from "vuetify/lib";
import { VExpansionPanels } from "vuetify/lib";
import { VExpansionPanel } from "vuetify/lib";
import { VExpansionPanelContent } from "vuetify/lib";
import { v4 } from "uuid";
import _ from "lodash";
import TemplateBuilderPublishedLabel from "@/components/templateCommon/TemplateBuilderPublishedLabel.vue";

export default {
  mixins: [formTemplateBuilderMixins],
  props: {
    template: Object,
    templateItems: Array,
    isLoading: Boolean,
    isCreatingItem: Boolean,
    categories: Array,
    isCms: Boolean,
  },
  data() {
    return {
      edit: false,
      name: {
        isValid: false,
      },
      description: {
        isValid: false,
        rules: [],
      },
      categoryId: {
        isValid: true,
      },
      isExpanded: true,
    };
  },
  methods: {
    updateTemplate(body) {
      this.$emit("template:update", { template: this.template, body });
    },
    updateTemplateField(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;

      const isValueEqual = _.isEqual(value, this.template[field]);
      if (isValueEqual) return;

      const isValid = this[field] && this[field].isValid;
      if (!isValid) return;

      this.updateTemplate({ [field]: value });
    },
    createItem(e) {
      const nextIndex = this.formTemplateBuilderMixins_getNextIndex(
        this.formTemplateBuilderMixins_getChildrenByParentId(
          this.templateItems,
          e.body.parentId,
        ),
      );
      const tempId = v4();

      this.formTemplateBuilderMixins_createItem({
        body: {
          ...e.body,
          index: nextIndex,
        },
        tempId,
      });

      this.scrollBottomOfElementToBottomOfPage(`form-template-item-${tempId}`, 150);
    },
    scrollBottomOfElementToBottomOfPage(elementId, offset) {
      this.$nextTick(() => {
        const scrollWindow = document.getElementById(
          "form-template-builder-scroll-window",
        );
        const element = document.getElementById(elementId);

        if (element && scrollWindow) {
          const rect = element.getBoundingClientRect();
          const scrollWindowRect = scrollWindow.getBoundingClientRect();

          const elementBottomRelativeToScrollWindow =
            rect.bottom - scrollWindowRect.top + scrollWindow.scrollTop;

          const adjustedElementBottomPosition =
            elementBottomRelativeToScrollWindow + offset;
          const visibleBottomBoundary =
            scrollWindow.scrollTop + scrollWindow.clientHeight;

          const isBottomOfElementVisible =
            adjustedElementBottomPosition <= visibleBottomBoundary;

          if (!isBottomOfElementVisible) {
            const scrollPosition =
              elementBottomRelativeToScrollWindow - scrollWindow.clientHeight + offset;

            scrollWindow.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
        }
      });
    },

    genPublishedLabel() {
      return this.$createElement(TemplateBuilderPublishedLabel, {
        props: {
          isLoading: this.isLoading,
          template: this.template,
        },
        on: {
          "template:update": (e) => this.updateTemplate(e),
        },
      });
    },
    genHeader() {
      return [
        this.$createElement(
          VCard,
          {
            class: "border-a mb-10",
            props: {
              flat: true,
              loading: this.isLoading,
              disabled: this.isLoading,
            },
          },
          [
            this.$createElement("div", { class: "pa-8" }, [
              this.genPublishedLabel(),
              this.$createElement(
                VForm,
                {
                  props: { value: this.name.isValid },
                  on: {
                    submit: (e) => e.preventDefault(),
                    input: (e) => (this.name.isValid = e),
                  },
                },
                [
                  this.$createElement(
                    "div",
                    { class: "pb-1 text-caption required-asterisk" },
                    this.$t("cms.template.form.name"),
                  ),
                  this.$createElement("AppDefaultTextField", {
                    props: {
                      value: this.template?.name,
                      rules: this.$rules.NAME_RULES,
                      counter: this.$rules.MAX_CHARACTERS,
                    },
                    on: { blur: (e) => this.updateTemplateField(e, "name") },
                  }),
                ],
              ),
              this.$createElement("div", [
                this.$createElement(
                  "div",
                  { class: "label--small" },
                  this.$t("common.category"),
                ),
                this.$createElement("AppDefaultAutocomplete", {
                  props: {
                    value: this.template?.category?.id || null,
                    items: this.categories,
                    placeholder: this.$t("cms.template.form.nav.details.formCategory"),
                    noDataText: this.$t("common.noCategories"),
                  },
                  on: {
                    change: (event) => this.updateTemplateField(event, "categoryId"),
                  },
                }),
              ]),

              this.$createElement(
                VForm,
                {
                  props: { value: this.description.isValid },
                  on: {
                    submit: (e) => e.preventDefault(),
                    input: (e) => (this.description.isValid = e),
                  },
                },
                [
                  this.$createElement(
                    "div",
                    { class: "text-caption pb-1" },
                    this.$t("cms.template.form.description"),
                  ),
                  this.$createElement("AppDefaultTextarea", {
                    props: {
                      hideDetails: true,
                      value: this.template?.description,
                      rules: this.description.rules,
                    },
                    on: { blur: (e) => this.updateTemplateField(e, "description") },
                  }),
                ],
              ),
            ]),
          ],
        ),
      ];
    },
    genSection(item, isFirstSection, isLastSection, sectionIndex) {
      return this.$createElement(
        VCard,
        {
          class: "border-a",
          props: {
            flat: true,
            disabled:
              this.isLoading || (item.isLoading && item?.type === this.$constant.SECTION),
          },
          attrs: {
            id: `form-template-item-${item.id}`,
          },
        },
        [
          this.$createElement(
            VExpansionPanels,
            {
              on: {
                change: (e) => {
                  if (e === 0) {
                    this.isExpanded = true;
                  } else {
                    this.isExpanded = false;
                  }
                },
              },
              props: {
                accordion: true,
                flat: true,
              },
              class: "p-unset",
            },
            [
              this.$createElement(
                VExpansionPanel,

                [
                  this.$createElement(
                    "div",
                    { class: "d-flex flex-column p-relative pa-4" },
                    [
                      this.$createElement(SectionHeader, {
                        props: { item, isFirstSection, isLastSection },
                        on: {
                          "item:delete": (e) =>
                            this.formTemplateBuilderMixins_deleteItem(e),
                          "item:update": (e) =>
                            this.formTemplateBuilderMixins_updateItem(e),
                          "item:move": (e) => this.formTemplateBuilderMixins_moveItem(e),
                        },
                      }),
                      this.$createElement(VExpansionPanelContent, [
                        this.$createElement(ItemHierarchy, {
                          props: {
                            template: this.template,
                            items: this.templateItems,
                            item,
                            isCreatingItem: this.isCreatingItem,
                            sectionIndex,
                            isCms: this.isCms,
                          },

                          on: {
                            "item:update": (e) =>
                              this.formTemplateBuilderMixins_updateItem(e),
                            "item:move": (e) =>
                              this.formTemplateBuilderMixins_moveItem(e),
                            "item:delete": (e) =>
                              this.formTemplateBuilderMixins_deleteItem(e),
                            "item:create": (e) => this.createItem(e),
                            "defaultAnswerTemplateMenu:open": (e) =>
                              this.formTemplateBuilderMixins_openDefaultAnswerTemplateMenu(
                                e,
                              ),
                          },
                        }),
                        this.genActionButtons(item),
                      ]),
                    ],
                  ),
                ],
              ),
            ],
          ),
        ],
      );
    },
    genItemBuilder(items) {
      return items.map((item, index) => {
        const isLast = index === items.length - 1;
        const isItemSection = item?.type === this.$constant.SECTION;
        const isLastSection = isItemSection && isLast;
        const isFirstSection = isItemSection && index === 0;
        return [
          this.genSection(item, isFirstSection, isLastSection, index),
          this.$createElement(
            "div",
            { class: "py-10 d-flex align-center justify-center" },
            [this.genAddPageBtn(item)],
          ),
        ];
      });
    },
    genActionButtons(item) {
      const onClick = ({ type, name }) => {
        return (e) => {
          const nextIndex = this.formTemplateBuilderMixins_getNextIndex(
            this.formTemplateBuilderMixins_getChildrenByParentId(
              this.templateItems,
              item.id,
            ),
          );
          const tempId = v4();

          this.formTemplateBuilderMixins_createItem({
            body: {
              parentId: item?.id,
              type,
              name,
              index: nextIndex,
            },
            tempId,
          });
          this.scrollBottomOfElementToBottomOfPage(`form-template-item-${tempId}`, 150);
        };
      };

      return this.$createElement("div", { class: "mt-4" }, [
        this.$createElement(
          VBtn,
          {
            class: "ui-background--background text-transform-none",
            props: {
              text: true,
              color: "primary",
              disabled: this.isCreatingItem,
              outlined: true,
              small: true,
            },
            on: {
              click: onClick({
                type: this.$constant.GROUP,
                name: this.$t("templateManagement.form.builder.item.newGroup"),
              }),
            },
          },
          [
            this.$createElement(
              VIcon,
              { props: { small: true } },
              this.$icon.REGULAR.ACTION.FORM_SECTION,
            ),
            this.$createElement(
              "div",
              { class: "pl-2" },
              this.$t("templateManagement.form.builder.item.addGroup"),
            ),
          ],
        ),
        this.$createElement(
          VBtn,
          {
            class: "ui-background--background ml-3 text-transform-none",
            props: {
              text: true,
              color: "primary",
              disabled: this.isCreatingItem,
              outlined: true,
              small: true,
            },
            on: {
              click: onClick({
                type: this.$constant.TEXTAREA,
                name: "",
              }),
            },
          },
          [
            this.$createElement(
              VIcon,
              { props: { small: true } },
              this.$icon.REGULAR.ACTION.ADD,
            ),
            this.$createElement(
              "div",
              { class: "pl-2" },
              this.$t("templateManagement.form.builder.item.addQuestion"),
            ),
          ],
        ),
      ]);
    },
    genAddPageBtn(item, isFirst = false) {
      const index = isFirst ? 0 : item.index + 1;
      return this.$createElement(
        VBtn,
        {
          class: `ui-background--background text-transform-none`,
          props: {
            color: "primary",
            depressed: true,
            disabled: this.isCreatingItem,
            rounded: true,
          },
          on: {
            click: (e) => {
              const tempId = v4();

              this.formTemplateBuilderMixins_createItem({
                body: {
                  name: this.$t("templateManagement.form.builder.item.newPage"),
                  parentId: null,
                  type: this.$constant.SECTION,
                  index,
                },
                tempId,
              });

              this.scrollBottomOfElementToBottomOfPage(
                `form-template-item-${tempId}`,
                90,
              );
            },
          },
        },
        [
          this.$createElement(
            VIcon,
            { props: { small: true } },
            this.$icon.REGULAR.FILE.FILE,
          ),
          this.$createElement(
            "div",
            { class: "pl-2" },
            this.$t("templateManagement.form.builder.item.addPage"),
          ),
        ],
      );
    },
    genCard() {
      const rootItems = this.formTemplateBuilderMixins_getRootItems(this.templateItems);
      const firstRootItem = rootItems[0];
      return this.$createElement("div", { class: "py-7" }, [
        this.genHeader(),
        ...(!!firstRootItem
          ? [
              this.$createElement(
                "div",
                { class: "pb-10 d-flex align-center justify-center" },
                [this.genAddPageBtn(firstRootItem, true)],
              ),
            ]
          : []),
        this.genItemBuilder(rootItems),
        //Redundant scroll height
        this.$createElement("div", { class: "redundant-scroll-height" }),
      ]);
    },
  },
  render(h) {
    return this.genCard();
  },
};
</script>
