<template>
  <AppMenuItem
    v-bind="$attrs"
    v-on="$listeners"
    :text="text"
    :icon="$icon.REGULAR.ACTION.SIGNATURE"
  />
</template>

<script>
export default {
  props: {
    isSentToSign: Boolean,
  },
  computed: {
    text() {
      return this.isSentToSign
        ? this.$t("formManagement.signatureSession.menuBtn.showSignStatus")
        : this.$t("formManagement.signatureSession.menuBtn.sendToSign");
    },
  },
};
</script>
