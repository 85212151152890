<template>
  <div class="text-border-reset">
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteTask"
      :item="selectedItem"
      :title="$t('taskManagement.deleteConfirmation.title')"
      :subtitle="$t('taskManagement.deleteConfirmation.description')"
      skip-validation
      @delete="taskDelete"
    />

    <AppDataTableServerSidePagination
      :loading="isLoading"
      :headers="_headers"
      :items="tasks"
      :tableModel="tableModel"
      :serverItemsLength="serverItemsLength"
      :noDataText="$t('common.noTasks')"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="1"
      disable-multi-select
    >
      <template v-slot:group.header="{ toggle, group, isOpen, headers }">
        <td :colspan="headers.length - 6" @click="toggle">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon small @click.stop="toggle">
              <v-icon>
                {{
                  isOpen ? $icon.LIGHT.COMMON.CHEVRON_DOWN : $icon.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div class="group-header__title">
              {{ headerTitle(group) }}
            </div>
          </div>
        </td>
        <td :colspan="6"></td>
      </template>
      <template v-slot:item="{ item, headers }">
        <AppTaskTableItem
          :task="item"
          :model="model"
          :isHighlighted="isHighlighted(item)"
          :headers="headers"
          :tableModel="tableModel"
          @task:update="onTaskUpdate"
          @task:select="selectedItem = item"
          @name:click="onTaskNameClick"
          @menu:click="onMenuClick"
          @filters:close="$emit('filters:close')"
          @status:click="onStatusClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu" @input="isContextMenuOpen = $event">
      <AppMenuDetailsBtn @click="onTaskDetailsClick" />
      <AppMenuCopyUrlBtn
        :route="{
          name: $routeNames.TASK_VIEW.ROOT,
          params: { taskId: selectedId },
        }"
      />
      <AppMenuDeleteBtn @click="onTaskDelete" :disabled="!canDeleteSelectedItem" />
    </AppContextMenu>
    <AppChangeCustomStatusIconMenu
      ref="statusMenu"
      @status:change="onStatusChange"
      model="task"
    />
  </div>
</template>

<script>
import { groupByMixins } from "@/helpers/mixins";

export default {
  mixins: [groupByMixins],
  props: {
    tasks: Array,
    isLoading: Boolean,
    taskId: String,
    model: String,
    tableModel: String,
    serverItemsLength: Number,
  },
  data() {
    return {
      dialog: {
        deleteTask: false,
      },
      selectedItem: null,
      isContextMenuOpen: false,
    };
  },
  computed: {
    columnsToFreeze() {
      return this.filtersMixins_dataTableOptions.groupBy?.includes(
        this.filtersMixins_dataFields.STATUS,
      )
        ? 1
        : 2;
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.taskName"),
          align: "start",
          value: this.filtersMixins_dataFields.NAME,
          show: true,
        },
        {
          text: "",
          align: "end",
          value: this.filtersMixins_dataFields.COUNT,
          sortable: false,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.responsible"),
          value: this.filtersMixins_dataFields.RESPONSIBLE,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.participants"),
          value: this.filtersMixins_dataFields.COLLABORATORS,
          show: true,
        },

        {
          text: this.$t("common.project"),
          value: this.filtersMixins_dataFields.PROJECT,
          show: this.model !== "project",
        },

        {
          text: this.$t("taskManagement.list.belongsToFormItem"),
          value: this.filtersMixins_dataFields.BELONGS_TO_FORM_ITEM,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.startDate"),
          value: this.filtersMixins_dataFields.START_DATE,
          show: true,
        },
        {
          text: this.$t("taskManagement.list.dueDate"),
          value: this.filtersMixins_dataFields.DUE_DATE,
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
    canDeleteSelectedItem() {
      if (!this.selectedItem) return false;
      return this.selectedItem.permissions?.canEdit;
    },
    selectedId() {
      return this.selectedItem?.id;
    },
  },
  methods: {
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      return group;
    },
    isHighlighted(item) {
      const isCurrentItem = this.taskId === item?.id || this.selectedId === item?.id;
      if (!isCurrentItem) return false;

      const isAnyDialogsOpen = Object.values(this.dialog).some((value) => value === true);
      const isDetailsOpen = item.id === this.taskId;

      return this.isContextMenuOpen || isAnyDialogsOpen || isDetailsOpen;
    },
    onStatusChange({ statusId, selectedItem }) {
      const body = { statusId };
      this.onTaskUpdate({ body, task: selectedItem });
    },
    onStatusClick({ pointerEvent, x, y, selectedItem }) {
      if (!selectedItem || selectedItem?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({
        pointerEvent,
        x,
        y,
        data: { statusId: selectedItem?.statusId, selectedItem },
      });
    },
    onMenuClick({ event, task }) {
      this.selectedItem = task;
      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onTaskNameClick({ task }) {
      this.selectedItem = task;
      this.onTaskDetailsClick();
    },
    onTaskDetailsClick() {
      this.$emit("task:details", { item: this.selectedItem });
    },
    onTaskUpdate({ body, task }) {
      this.$emit("task:update", { data: body, item: task });
    },
    onTaskDelete() {
      this.dialog.deleteTask = true;
    },
    taskDelete({ item }) {
      this.$emit("task:delete", { item });
    },
  },
};
</script>
