import _ from "lodash";
import i18n from "../plugins/i18n";

export const MAX_CHARACTERS = 255;
const TEXT = {
  REQUIRED: i18n.t("common.required"),
  MAX_CHARACTERS: i18n.t("common.maxCharacters", {
    amount: MAX_CHARACTERS,
  }),
  INVALID_EMAIL: i18n.t("users.userInvite.invalidEmail"),
  PASSWORD: {
    CHARACTERS: i18n.t("settings.changePassword.rules.characters"),
    NUMBER: i18n.t("settings.changePassword.rules.number"),
    UPPER_CASE: i18n.t("settings.changePassword.rules.upperCase"),
    LOWER_CASE: i18n.t("settings.changePassword.rules.lowerCase"),
  },
  MUST_BE_INTEGER: i18n.t("rules.mustBeInteger"),
  INVALID_LINK: i18n.t("rules.invalidLink"),
};

export const EMAIL_RULES = [
  (v) => !v || v.length <= MAX_CHARACTERS || TEXT.MAX_CHARACTERS,
  (v) =>
    !v ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9æøåÆØÅ]+\.)+[a-zA-Z]{2,24}))$/.test(
      v,
    ) ||
    TEXT.INVALID_EMAIL,
];
export const EMAIL_NOT_REQUIRED_RULES = [
  (v) =>
    !v ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9æøåÆØÅ]+\.)+[a-zA-Z]{2,24}))$/.test(
      v,
    ) ||
    TEXT.INVALID_EMAIL,
  (v) => !v || v.length <= MAX_CHARACTERS || TEXT.MAX_CHARACTERS,
];
export const PASSWORD_RULES = [
  (v) => !!v || TEXT.REQUIRED,
  (v) => v?.length >= 8 || TEXT.PASSWORD.CHARACTERS,
  (v) => /[0-9]/.test(v) || TEXT.PASSWORD.NUMBER,
  (v) => /[A-Z]/.test(v) || TEXT.PASSWORD.UPPER_CASE,
  (v) => /[a-z]/.test(v) || TEXT.PASSWORD.LOWER_CASE,
];
export const NAME_RULES = [
  (v) => !!v || TEXT.REQUIRED,
  (v) => (v && !!v.trim()) || TEXT.REQUIRED,
  (v) => (v ? v.length <= MAX_CHARACTERS || TEXT.MAX_CHARACTERS : ""),
];

export const REQUIRED = [
  (v) => {
    if (_.isArray(v)) {
      return (
        (v.length > 0 &&
          v.some((item) => {
            if (_.isObject(item)) {
              return Object.values(item).some((value) => isNotEmptyPrimitive(value));
            }
            return isNotEmptyPrimitive(item);
          })) ||
        TEXT.REQUIRED
      );
    }

    if (_.isObject(v)) {
      return (
        Object.values(v).some((value) => isNotEmptyPrimitive(value)) || TEXT.REQUIRED
      );
    }

    return isNotEmptyPrimitive(v) || TEXT.REQUIRED;
  },
];

const isNotEmptyPrimitive = (v) => {
  if (_.isNil(v)) {
    return false;
  }

  if (_.isBoolean(v)) {
    return v;
  }

  if (_.isNumber(v)) {
    return true;
  }

  return !_.isEmpty(v);
};

export const NOT_ONLY_WHITESPACES = [(v) => (v && !!v.trim()) || TEXT.REQUIRED];
export const TOO_MANY_CHARACTERS = [
  (v) => (v ? v.length <= MAX_CHARACTERS || TEXT.MAX_CHARACTERS : ""),
];

export const INTEGER = [(v) => /^[0-9]*$/.test(v) || TEXT.MUST_BE_INTEGER];

export const URL_RULES = [
  (v) =>
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g.test(
      v,
    ) || TEXT.INVALID_LINK,
];
