<template>
  <div class="drawer_node border-b">
    <div class="drawer_node__root" @click="onClick(item)">
      <div class="drawer_node__item">
        <div class="w-100 d-flex pb-2 align-center">
          <AppCustomStatusIcon
            @click="onStatusClick"
            model="form"
            :statusId="statusId"
            :loading="item?.isUpdatingStatus"
            :disabled="item?.isUpdatingStatus"
          />
          <AppLinkText class="pl-4" @click="onNameClick(item)" underline-on-hover>
            {{ item.name }}
          </AppLinkText>
        </div>
        <div class="w-100 d-flex align-center pb-2">
          <div v-if="item.member">
            <span class="label--small">{{ $t("formManagement.list.responsible") }}</span>
            <AppUserAvatar :user="item.member" show-name :truncate="0" top />
          </div>
        </div>
        <div class="w-100 d-flex align-center">
          <AppFormProgressionChip :progress="item.progress" />
          <v-spacer />
          <v-btn icon color="error" @click="deleteForm(item)">
            <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <AppChangeCustomStatusIconMenu
      ref="statusMenu"
      @status:change="updateForm({ statusId: $event.statusId })"
      model="form"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  computed: {
    statusId() {
      return this.item?.statusId;
    },
  },
  methods: {
    onClick(item) {
      this.$emit("item:click", { item });
    },
    onNameClick(item) {
      this.$emit("item:nameClick", { item });
    },
    updateForm(body) {
      this.$emit("item:update", { body, form: this.item });
    },
    deleteForm(form) {
      this.$emit("item:delete", { form });
    },
    onStatusClick({ pointerEvent, x, y }) {
      if (!this.item || this.item?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({
        pointerEvent,
        x,
        y,
        data: { statusId: this.statusId },
      });
    },
  },
};
</script>
