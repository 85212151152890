<script>
import { projectTemplateBuilderMixins } from "@/helpers/mixins";
import { VContainer, VCard, VForm } from "vuetify/lib";
import Title from "./Title.vue";
import Tasks from "./Tasks.vue";
import DocumentTemplates from "./DocumentTemplates.vue";
import Storage from "./Storage.vue";
import Todos from "./Todos.vue";
import TemplateBuilderPublishedLabel from "@/components/templateCommon/TemplateBuilderPublishedLabel.vue";

export default {
  mixins: [projectTemplateBuilderMixins],
  props: {
    template: Object,
    templateItems: Array,
    storageTreeView: Array,
    isLoading: Boolean,
    showHSE: Boolean,
    showQA: Boolean,
    isAddingHSEDocuments: Boolean,
    isAddingQADocuments: Boolean,
  },
  data() {
    return {
      name: {
        isValid: false,
      },
      description: {
        isValid: false,
        rules: [],
      },
    };
  },
  methods: {
    updateTemplate(body) {
      this.$emit("template:update", { template: this.template, body });
    },
    onBlur(e, field) {
      const value = typeof e === "object" && e !== null ? e?.target?.value : e;
      if (typeof value !== "undefined" && value !== this.template[field]) {
        const isFormField = this[field] ? true : false;
        if (isFormField && !this[field].isValid) return;
        this.updateTemplate({ [field]: value });
      }
    },
    genCard() {
      return this.$createElement(
        VCard,
        {
          class: "border-a",
          props: {
            flat: true,
            loading: this.isLoading,
            disabled: this.isLoading,
          },
        },
        [
          this.$createElement("div", { class: "pa-8" }, [
            this.genPublishedLabel(),
            this.genHeader(),
            this.genTasks(),
            this.genTodos(),
            this.showHSE && this.genDocumentTemplates("HSE"),
            this.showQA && this.genDocumentTemplates("QA"),
            this.genStorage(),
          ]),
        ],
      );
    },
    genStorage() {
      const storageItems = this.templateItems.filter((t) => t.type === "STORAGE");
      return this.$createElement(Storage, {
        props: {
          storageItems,
          storageTreeView: this.storageTreeView,
        },
        on: {
          "item:createUnsavedItem": (e) => this.$emit("item:createUnsavedItem", e),
          "item:update": (e) => this.$emit("item:update", e),
          "item:delete": (e) => this.$emit("item:delete", e),
        },
      });
    },
    genTodos() {
      const toDoItems = this.templateItems.filter((t) => t.type === "TODO");
      return this.$createElement(Todos, {
        props: { toDoItems },
        on: {
          "item:createUnsavedItem": (e) => this.$emit("item:createUnsavedItem", e),
          "item:update": (e) => this.$emit("item:update", e),
          "item:delete": (e) => this.$emit("item:delete", e),
          "item:move": (e) => this.projectTemplateBuilderMixins_moveItem(e),
        },
      });
    },
    genTasks() {
      const taskItems = this.templateItems.filter((t) => t.type === "TASK");
      return this.$createElement(Tasks, {
        props: { taskItems },
        on: {
          "item:createUnsavedItem": (e) => this.$emit("item:createUnsavedItem", e),
          "item:update": (e) => this.$emit("item:update", e),
          "item:delete": (e) => this.$emit("item:delete", e),
          "item:move": (e) => this.projectTemplateBuilderMixins_moveItem(e),
        },
      });
    },
    genDocumentTemplates(type) {
      const documentTemplateItems = this.templateItems.filter((t) => t.type === type);
      return this.$createElement(DocumentTemplates, {
        props: {
          templateId: this.template?.id,
          documentTemplateItems,
          type,
          isAddingDocuments: this[`isAdding${type}Documents`],
        },
        on: {
          "items:create": (e) => this.$emit("items:create", e),
          "item:update": (e) => this.$emit("item:update", e),
          "item:delete": (e) => this.$emit("item:delete", e),
        },
      });
    },
    genPublishedLabel() {
      return this.$createElement(TemplateBuilderPublishedLabel, {
        props: {
          isLoading: this.isLoading,
          template: this.template,
        },
        on: {
          "template:update": (e) => this.updateTemplate(e),
        },
      });
    },
    genHeader() {
      return [
        this.$createElement(
          VForm,
          {
            props: { value: this.name.isValid },
            on: {
              submit: (e) => e.preventDefault(),
              input: (e) => (this.name.isValid = e),
            },
          },
          [
            this.$createElement(Title, {
              class: "required-asterisk",
              props: {
                title: this.$t("cms.template.form.name"),
              },
            }),
            this.$createElement("AppDefaultTextField", {
              class: "mb-7",
              props: {
                hideDetails: true,
                value: this.template?.name,
                rules: this.$rules.NAME_RULES,
                dense: false,
              },
              on: { blur: (e) => this.onBlur(e, "name") },
            }),
          ],
        ),
        this.$createElement(
          VForm,
          {
            props: { value: this.description.isValid },
            on: {
              submit: (e) => e.preventDefault(),
              input: (e) => (this.description.isValid = e),
            },
          },
          [
            this.$createElement(Title, {
              props: {
                title: this.$t("cms.template.form.description"),
              },
            }),

            this.$createElement("AppDefaultTextarea", {
              class: "mb-7",
              props: {
                hideDetails: true,
                id: "descriptionField",
                value: this.template?.description,
                rules: this.description.rules,
                dense: false,
              },
              on: { blur: (e) => this.onBlur(e, "description") },
            }),
          ],
        ),
      ];
    },
  },
  render(h) {
    return h(VContainer, [
      this.genCard(),
      //Redundant scroll height
      this.$createElement("div", { class: "redundant-scroll-height" }),
    ]);
  },
};
</script>
