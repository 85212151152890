<template>
  <v-btn text @click="$emit('click')" class="add-file-btn" color="primary">
    <v-icon class="pr-3">{{ $icon.LIGHT.ACTION.ADD }}</v-icon>
    <span class="font-weight-bold">
      {{ $t("common.selectFiles") }}
    </span>
  </v-btn>
</template>

<script>
export default {};
</script>

<style scoped>
.add-file-btn {
  margin-top: 8px;
}
</style>
