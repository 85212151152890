<template>
  <v-dialog
    v-model="localDialog"
    min-width="500px"
    max-width="500px"
    id="change-password-widget-container"
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("settings.changePassword.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="changePasswordForm"
          v-model="changePassword.valid"
          v-on:submit.prevent
          @submit="onSave"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.changePassword.oldPassword") }}
            </div>
            <AppDefaultTextField
              v-model="changePassword.oldPassword.value"
              :rules="$rules.PASSWORD_RULES"
              :append-icon="
                changePassword.oldPassword.show
                  ? $icon.LIGHT.COMMON.EYE_SLASH
                  : $icon.LIGHT.COMMON.EYE
              "
              :type="changePassword.oldPassword.show ? 'text' : 'password'"
              @click:append="
                changePassword.oldPassword.show = !changePassword.oldPassword.show
              "
              autofocus
            />
          </div>

          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.changePassword.newPassword") }}
            </div>
            <AppDefaultTextField
              v-model="changePassword.newPassword.value"
              :rules="changePassword.newPassword.rules"
              :label="$t('settings.changePassword.newPassword')"
              :append-icon="
                changePassword.newPassword.show
                  ? $icon.LIGHT.COMMON.EYE
                  : $icon.LIGHT.COMMON.EYE_SLASH
              "
              :type="changePassword.newPassword.show ? 'text' : 'password'"
              @click:append="
                changePassword.newPassword.show = !changePassword.newPassword.show
              "
            />
          </div>

          <v-row>
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="error"
                v-if="error && changePassword.showError"
              >
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSave"
          @cancel="onClose"
          :confirmText="$t('common.save')"
          :loading="isChangingPassword"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      changePassword: {
        showError: false,
        valid: false,
        oldPassword: {
          value: "",
          rules: [(v) => !!v || this.$t("common.required")],
          show: false,
        },
        newPassword: {
          value: "",
          show: false,
          rules: [(v) => !!v || this.$t("common.required")],
        },
      },
    };
  },
  watch: {
    error() {
      this.changePassword.showError = true;
    },
  },
  computed: {
    ...mapState("auth", {
      isChangingPassword: (state) => state.changePassword.isChanging,
      error: (state) => state.changePassword.error,
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.localDialog = false;
    },
    resetDialog() {
      this.changePassword.showError = false;
      this.$refs.changePasswordForm.reset();
    },
    onClose() {
      this.closeDialog();
      this.resetDialog();
    },
    async onSave() {
      const valid = this.$refs.changePasswordForm.validate();
      if (valid) {
        const data = {
          oldPassword: this.changePassword.oldPassword.value,
          newPassword: this.changePassword.newPassword.value,
        };
        await this.$store.dispatch("auth/changePassword", data);
        this.closeDialog();
        this.resetDialog();
      }
    },
  },
  mounted() {},
};
</script>
