/**
 * Determines whether to update the items in a paginated list.
 *
 * This function is used to decide if the current page's items should be updated
 * in the store or component state. It prevents showing an empty list when
 * filtering results or navigating, especially when the current page number
 * becomes invalid due to a reduced number of total items.
 *
 * @param {number} count - The total number of items across all pages.
 * @param {number} currentPage - The current page number.
 * @param {number} itemsPerPage - The number of items displayed per page.
 * @returns {boolean} True if the items should be updated, false otherwise.
 */
export function shouldUpdateItems(count, currentPage, itemsPerPage) {
  //if its not a data table page, always update
  if (currentPage === undefined || itemsPerPage === undefined) return true;

  if (count === 0) return true;

  const lastPageWithItems = Math.ceil(count / itemsPerPage);
  return currentPage <= lastPageWithItems;
}
