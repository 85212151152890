<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("users.userInvite.title") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="inviteForm"
          v-model="formValid"
          v-on:submit.prevent
          @submit="onInvite"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.email") }}
            </div>
            <AppDefaultTextField
              ref="emailInput"
              v-model="currentEmail.value"
              :rules="currentEmail.rules"
              @keydown.enter.prevent="addEmail"
              @blur="addEmail"
              autofocus
              clearable
            />
          </div>
          <v-chip-group column>
            <v-chip
              v-for="(email, index) in emails"
              :ref="`emailChip-${index}`"
              :key="index"
              close
              :closeIcon="$icon.LIGHT.ACTION.CLOSE"
              @click="editEmail(index)"
              @click:close="removeEmail(index)"
            >
              {{ email }}
            </v-chip>
          </v-chip-group>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onInvite"
          @cancel="onClose"
          :disabled="!emails.length > 0"
          :confirmText="$t('users.userInvite.invite')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  data() {
    return {
      formValid: false,
      currentEmail: {
        value: "",
        rules: [...this.$rules.EMAIL_RULES, this.checkDuplicateEmail],
      },
      emails: [],
      editingIndex: null,
    };
  },
  methods: {
    addEmail() {
      if (
        this.currentEmail.value &&
        this.currentEmail.rules.every((rule) => rule(this.currentEmail.value) === true)
      ) {
        if (this.editingIndex !== null) {
          this.emails.splice(this.editingIndex, 1, this.currentEmail.value);
          this.$nextTick(() => {
            const chipRef = this.$refs[`emailChip-${this.editingIndex}`];
            chipRef[0].toggle();
            this.editingIndex = null;
          });
        } else {
          this.emails.push(this.currentEmail.value);
        }
        this.currentEmail.value = "";
        this.$nextTick(() => {
          this.$refs.emailInput.focus();
        });
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    editEmail(index) {
      this.currentEmail.value = this.emails[index];
      this.editingIndex = index;
      this.$nextTick(() => {
        this.$refs.emailInput.focus();
      });
    },
    onInvite() {
      if (!this.$refs.inviteForm.validate()) return;
      if (this.formValid && this.emails.length > 0) {
        const data = {
          emails: this.emails,
        };
        this.$emit("submit:form", data);
        this.resetForm();
        this.closeDialog();
      }
    },
    resetForm() {
      this.$refs.inviteForm.reset();
      this.emails = [];
      this.currentEmail.value = "";
    },

    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.inviteForm.resetValidation();
    },
    checkDuplicateEmail(email) {
      if (this.editingIndex !== null) {
        const otherEmails = this.emails.filter((_, index) => index !== this.editingIndex);
        return !otherEmails.includes(email) || this.$t("users.userInvite.duplicateEmail");
      }
      return !this.emails.includes(email) || this.$t("users.userInvite.duplicateEmail");
    },
  },
};
</script>
