import axios from "axios";
import { normalizeForm, normalizeForms } from "@/helpers/normalization";
import { formatDataTableOptionsToQuery } from "@/helpers/util/dataTable";
import { shouldUpdateItems } from "@/helpers/util";
const apiUrl = process.env.VUE_APP_API_URL;
const CancelToken = axios.CancelToken;
let getFormsCancel;

const state = () => ({
  forms: [],
  count: null,
  isLoading: false,
  isConverting: false,
});

const getters = {
  getFormById: (state) => (id) => {
    return state.forms.find((form) => form.id === id);
  },
};

const actions = {
  async getForms({ commit }, { filters, dataTableOptions } = {}) {
    if (getFormsCancel) getFormsCancel();

    commit("loading", true);

    const optionsQuery = formatDataTableOptionsToQuery(dataTableOptions);

    const params = {
      ...filters,
      ...optionsQuery,
    };

    return axios
      .get(`${apiUrl}form`, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          getFormsCancel = c;
        }),
      })
      .then(({ data }) => {
        const { forms, count } = data;

        const shouldUpdate = shouldUpdateItems(
          count,
          dataTableOptions?.page,
          dataTableOptions?.itemsPerPage,
        );

        if (shouldUpdate) {
          const normalziedForms = normalizeForms(forms);
          commit("setForms", normalziedForms);
        }
        commit("setCount", count);
        commit("loading", false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          commit("loading", false);
          throw new Error(error);
        }
      });
  },
  async convertTemplate({ commit, state }, { id, body }) {
    commit("converting", true);
    return axios
      .post(`${apiUrl}template/form/${id}/convert`, { ...body })
      .then(({ data }) => {
        commit("converting", false);
        commit("setCount", state.count + 1);
        return data?.form;
      })
      .catch((error) => {
        commit("converting", false);
        throw new Error(error);
      });
  },
  async updateForm({ commit }, { id, body }) {
    if (typeof body?.statusId === "string") {
      commit("toggleUpdatingStatus", {
        id,
        isUpdatingStatus: true,
      });
    }

    return axios
      .put(`${apiUrl}form/${id}`, { ...body })
      .then(({ data }) => {
        const form = data?.form;
        if (form) {
          const normalizedForm = normalizeForm(form);
          commit("updateForm", { form: normalizedForm });
        } else {
          commit("removeForm", { id });
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("toggleUpdatingStatus", {
          id,
          isUpdatingStatus: false,
        });
      });
  },
  async deleteForm({ commit, state }, { id }) {
    return axios
      .delete(`${apiUrl}form/${id}`)
      .then(({ data }) => {
        const form = data?.form;
        if (form) {
          commit("removeForm", form);
          commit("setCount", state.count - 1);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  resetState({ commit }) {
    commit("setForms", []);
    commit("setCount", null);
  },
  removeFormTaskId({ commit, state }, { formId }) {
    const form = state.forms.find((form) => form.id === formId);
    commit("updateForm", { form, taskId: null });
  },
};

const mutations = {
  setForms(state, forms) {
    state.forms = forms;
  },
  setCount(state, count) {
    state.count = count;
  },
  updateForm(state, { form } = {}) {
    if (!form) throw new Error("No form");
    const index = state.forms.findIndex((x) => x.id === form.id);
    if (index !== -1) {
      state.forms.splice(index, 1, form);
    }
  },
  removeForm(state, form) {
    if (!form) throw new Error("No form");
    const index = state.forms.findIndex((x) => x.id === form.id);
    if (index !== -1) {
      state.forms.splice(index, 1);
    }
  },
  loading(state, isLoading) {
    state.isLoading = isLoading;
  },
  converting(state, isConverting) {
    state.isConverting = isConverting;
  },
  toggleUpdatingStatus(state, { id, isUpdatingStatus }) {
    const index = state.forms.findIndex((x) => x.id === id);
    if (index !== -1) {
      if (state.forms[index]) {
        state.forms.splice(index, 1, { ...state.forms[index], isUpdatingStatus });
      }
    }
  },
};

export const forms = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
