<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("templatePicker.title", { count: templates.length }) }}
        </span>
      </v-card-title>
      <v-card-text>
        <div class="pb-4">
          <AppDefaultTextField
            :label="$t('common.search')"
            :append-icon="$icon.LIGHT.ACTION.SEARCH"
            hide-details
            clearable
            :value="filter.search"
            @input="debounceSearch"
          />
        </div>
        <v-simple-table
          class="border-a overflow-hidden rounded"
          fixed-header
          height="400px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left input-background" style="height: 30px">
                  <span class="text--disabled">
                    {{ $t("templatePicker.templates") }} ({{ templates.length }})
                  </span>
                </th>
                <th class="input-background" style="height: 30px"></th>
              </tr>
            </thead>
            <tbody class="border-radius-0">
              <tr
                v-for="(item, i) in templates"
                :key="i"
                class="cursor-pointer"
                :class="
                  item.id ? (item.id === templateId ? 'list_tr--selected' : '') : ''
                "
                @click="selectTemplate(item)"
              >
                <td class="border-radius-0">
                  <AppTextTruncateValue :text="item.name" :truncate="50" />
                </td>
                <td class="border-radius-0 text-right">
                  <AppDefaultTooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="onPreviewDialogOpen(item.id)"
                        class="mr-2"
                      >
                        <v-icon>{{ $icon.LIGHT.COMMON.EYE }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("common.preview") }}</span>
                  </AppDefaultTooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSelect"
          @cancel="localDialog = false"
          :loading="isAddingTemplate"
          :disabled="!templateId"
          :confirmText="$t('templatePicker.applyTemplate')"
        />
      </v-card-actions>
      <template v-if="type === $constant.PROJECT">
        <AppProjectTemplatePreviewDialog
          v-model="previewDialiog.active"
          :templateId="previewDialiog.templateId"
        />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    type: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      filter: {
        search: null,
      },
      searchDebounce: null,
      templateId: null,
      previewDialiog: {
        active: false,
        templateId: null,
      },
      isAddingTemplate: false,
    };
  },
  computed: {
    templates() {
      if (this.type === this.$constant.PROJECT) {
        return this.$store.state.selectableProjectTemplates.templates;
      }
      return [];
    },
    isLoading() {
      if (this.type === this.$constant.PROJECT) {
        return this.$store.state.selectableProjectTemplates.isLoading;
      }
      return false;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getTemplates(this.filterArgs());
        }
      },
    },
    filter: {
      handler(filter) {
        this.getTemplates(this.filterArgs(filter));
      },
      deep: true,
    },
  },
  methods: {
    getTemplates(args) {
      if (this.type === this.$constant.PROJECT) {
        this.$store.dispatch("selectableProjectTemplates/getTemplates", args);
      }
    },
    selectTemplate(template) {
      if (!template) return;
      if (this.templateId === template.id) {
        this.templateId = null;
        return;
      }
      this.templateId = template.id;
    },
    onPreviewDialogOpen(templateId) {
      this.previewDialiog.active = true;
      this.previewDialiog.templateId = templateId;
    },
    afterTemplateSelect() {
      this.localDialog = false;
      this.templateId = null;
      this.isAddingTemplate = false;
    },
    onSelect() {
      if (!this.templateId) return;
      this.isAddingTemplate = true;
      this.$emit("select", {
        templateId: this.templateId,
        afterTemplateSelect: () => this.afterTemplateSelect(),
      });
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filter.search = event;
      }, 600);
    },
    filterArgs(args) {
      return {
        ...this.filter,
        ...args,
      };
    },
  },
};
</script>
