<template>
  <div
    class="file_storage h-100"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="dragleave"
  >
    <AppNewFolderDialog
      permissionLevel
      v-model="dialog.folderCreate.active"
      :data="dialog.folderCreate.data"
      @submit:form="afterItemCreate($event, $constant.FOLDER)"
    />
    <AppEditFolderDialog
      v-model="dialog.folderEdit.active"
      :data="dialog.folderEdit.data"
      @submit:form="afterItemEdit"
    />
    <AppEditFileDialog
      v-model="dialog.fileEdit.active"
      :data="dialog.fileEdit.data"
      @submit:form="afterItemEdit"
    />
    <AppLinkFilesDialog
      v-model="dialog.link.active"
      :files="dialog.link.files"
      :parentId="dialog.link.parentId"
    />
    <AppMoveStorageDialog
      v-model="dialog.move.active"
      :selectedStorages="dialog.move.storage"
      :permissionLevel="dialog.move.permissionLevel"
      :parentId="dialog.move.parentId"
      @after:save="afterMove"
    />
    <AppDeleteConfirmationDialog
      ref="deleteConfirmation"
      v-model="dialog.deleteConfirmation.active"
      :title="$t('fileManagement.fileExplorer.deleteConfirmation.title')"
      :subtitle="$t('fileManagement.fileExplorer.deleteConfirmation.subTitle')"
      :validator="$t('common.delete').toLowerCase()"
      :validatorText="
        $t('fileManagement.fileExplorer.deleteConfirmation.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="afterDeleteConfirmation"
    />
    <AppBoligmappaFileUploadDialog
      v-if="showBoligMappa"
      v-model="dialog.boligmappaFileUpload.active"
      :boligmappaNumber="boligmappa.boligmappaNumber"
      :projectId="boligmappa.projectId"
      :files="dialog.boligmappaFileUpload.files"
      @created="afterBoligmappaFileUpload"
    />
    <AppMergeToPdfDialog
      ref="mergeToPdfDialog"
      v-model="dialog.merge.active"
      :parentId="parentId"
      :items="selectedFilesToMerge"
      @items:change="onSelectedFilesToMergeUpdate"
      @item:remove="onSelectedFilesRemove"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.mergeClear.active"
      :title="$t('fileManagement.merge.clear.title')"
      :subtitle="$t('fileManagement.merge.clear.subTitle')"
      skip-validation
      :deleteBtnText="$t('fileManagement.merge.clear.validator')"
      @delete="clearMerge"
    />
    <AppSideNav ref="sideNav" class="border-l-none" id="sideNav">
      <template v-slot:navigation>
        <v-toolbar
          class="border-b"
          v-if="toolbarType === 'SEARCH'"
          dense
          flat
          fixed
          height="68"
        >
          <v-btn icon @click="setToolbarType('DEFAULT')">
            <v-icon>{{ $icon.LIGHT.COMMON.ARROW_LEFT }}</v-icon>
          </v-btn>
          <AppDefaultTextField
            hide-details
            autofocus
            clearable
            :append-icon="$icon.LIGHT.ACTION.SEARCH"
            :label="$t('common.search')"
            :value="search"
            @input="debounceSearch"
          />
        </v-toolbar>

        <v-toolbar class="border-b" dense flat fixed height="68" v-else>
          <v-toolbar-title class="font-weight-bold"
            >{{ title ? title : $t("fileManagement.fileExplorer.mainToolbar.title") }}
          </v-toolbar-title>

          <v-spacer />

          <AppStorageCreateMenu
            :folderId="folderId"
            hide-document-template-actions
            icon-button
          />

          <AppDefaultTooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="isLoading"
                icon
                @click="setToolbarType('SEARCH')"
              >
                <v-icon>{{ $icon.LIGHT.ACTION.SEARCH }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("fileManagement.fileExplorer.mainToolbar.search") }}</span>
          </AppDefaultTooltip>
        </v-toolbar>
        <AppStorageSearch
          v-model="showSearch"
          :search="search"
          :rootId="searchRootId"
          :show-move="showMove"
          :show-link="showLink"
          :show-download="showDownload"
          :show-delete="showDelete"
          :action-left="actionLeft"
          :action-right="actionRight"
          @item:click="onSearchItemClick"
          @after:move="afterSearchItemMove"
          @after:delete="afterSearchDelete"
        />
        <div class="file_storage__file_explorer_container" v-show="!showSearch">
          <AppActionBar v-model="showActionBar" id="actionBar">
            <template v-slot:actions>
              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showMove"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="info"
                    text
                    icon
                    :disabled="!canMove"
                    @click="onMove"
                  >
                    <v-icon>{{ $icon.LIGHT.FILE.FILE_EXPORT }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("fileManagement.actionBar.move") }}</span>
              </AppDefaultTooltip>

              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showLink"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="info"
                    text
                    icon
                    @click="onLink"
                    :disabled="!canLink"
                  >
                    <v-icon>{{ $icon.LIGHT.FILE.FILE_LINKED }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("fileManagement.linkedFile.title") }}</span>
              </AppDefaultTooltip>

              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showDownload"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="info"
                    text
                    icon
                    @click="onDownload"
                    :loading="isDownloading"
                    :disabled="!canDownload"
                  >
                    <v-icon>{{ $icon.LIGHT.ACTION.DOWNLOAD }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("common.download") }}</span>
              </AppDefaultTooltip>

              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showBoligMappa"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize text--disabled"
                    color="info"
                    text
                    icon
                    :style="!canUploadToBoligmappa ? 'opacity: 0.5;' : ''"
                    :disabled="!canUploadToBoligmappa"
                    @click="onBoligmappaFileUpload"
                  >
                    <v-icon>$boligmappa</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("integration.boligmappa.fileUpload.title") }}</span>
              </AppDefaultTooltip>

              <AppDefaultTooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showDelete"
                    v-bind="attrs"
                    v-on="on"
                    class="text-capitalize"
                    color="error"
                    text
                    icon
                    :disabled="!localCanDelete"
                    @click="onDelete"
                  >
                    <v-icon>{{ $icon.LIGHT.ACTION.DELETE }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("fileManagement.actionBar.delete") }}</span>
              </AppDefaultTooltip>
            </template>
          </AppActionBar>

          <v-toolbar
            :class="[{ 'border-b': !isLoading }]"
            dense
            flat
            height="24"
            extensionHeight="44"
          >
            <div class="label--small" style="padding-bottom: 0px !important">
              {{ $t("fileManagement.fileExplorer.navToolbar.title") }}
            </div>

            <template v-slot:extension>
              <v-checkbox
                :value="isAllSelected"
                @change="onSelectAllToggle"
                :disabled="!canSelectAll"
                :indeterminate="isIndeterminate"
                @click.stop
                dense
                hide-details
                click.stop
                color="primary"
                style="padding-top: 1px"
              />
              <AppFolderPath
                :prependPath="prependPath"
                :truncatedPath="truncatedPath"
                @item:click="onPathItemClick"
              />

              <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
              />
            </template>
          </v-toolbar>

          <div class="file_storage__file_explorer" @drop="drop">
            <div :class="[isDragging ? 'dropZone' : 'd-none']" @drop="drop">
              <div class="dropZone-overlay">
                <v-row
                  class="fill-height w-100 ma-0"
                  align-content="center"
                  justify="center"
                >
                  <v-col class="subtitle-1 text-center pb-0" cols="12">
                    <v-img
                      contain
                      max-height="100"
                      src="https://go-digital.azureedge.net/assets/images/upload.png"
                    />
                  </v-col>
                  <v-col class="subtitle-1 text-center pb-0" cols="12">
                    <h2>
                      {{ $t("fileManagement.fileExplorer.dropZone.title") }}
                    </h2>
                  </v-col>
                </v-row>
              </div>
            </div>
            <template v-if="!isLoading">
              <template v-if="items.length > 0">
                <div
                  :class="[isDragging ? 'opacity-30' : '']"
                  v-for="item of items"
                  :key="item.id"
                >
                  <AppStorageItem
                    item-click
                    :item="item"
                    :active="activeFileId"
                    @item:click="onItemClick"
                  >
                    <template v-slot:prepend="{ item, state }">
                      <AppStorageThumbnail :storage="item" :loading="item.isLoading">
                        <template v-if="state.hover || state.isSelected" v-slot:content>
                          <v-checkbox
                            class="ma-0"
                            v-model="state.isSelected"
                            @change="onItemSelectToggle(item)"
                            @click.stop
                            @mousedown.stop
                            dense
                            hide-details
                            click.stop
                          />
                        </template>
                      </AppStorageThumbnail>
                    </template>
                    <template v-slot:label="{ item }">
                      <div class="w-100 d-flex pl-3">
                        <div>
                          {{ item.name }}
                          <span
                            v-if="item.type === $constant.FOLDER"
                            class="font-weight-bold"
                          >
                            ({{ storageMixins_getStorageCount(item) }})
                          </span>
                        </div>
                      </div>
                      <AppStorageItemInfoRow :storage="item" />
                    </template>
                    <template v-slot:append="{ item }">
                      <div class="h-100 d-flex align-center">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" @click.stop @mousedown.stop>
                              <v-btn icon>
                                <v-icon>{{ $icon.REGULAR.ACTION.MENU }}</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <div
                            class="pa-3 ui-background d-flex flex-column"
                            style="min-width: 200px"
                          >
                            <AppMenuItem
                              :text="
                                $t(
                                  'fileManagement.fileExplorer.menu.showInDocumentCenter',
                                )
                              "
                              :icon="$icon.REGULAR.ACTION.POP_OUT"
                              @click="openInDocumentCenter(item)"
                            />
                            <AppMenuItem
                              :text="$t('fileManagement.fileExplorer.menu.rename')"
                              :icon="$icon.REGULAR.ACTION.EDIT"
                              :disabled="item.type === $constant.FILE_RELATION"
                              @click="onItemEdit(item)"
                            />
                            <AppMenuItemOpenInOffice :item="item" />
                            <AppMenuItem
                              :text="$t('fileManagement.fileExplorer.menu.addToMerge')"
                              :icon="$icon.REGULAR.COMMON.MERGE"
                              :disabled="!canMergeFile(item)"
                              @click="onAddToMerge(item)"
                              :showStar="!planRestrictionsMixins_canMergeFiles"
                              :planCode="$constant.PLAN_CODE.GRIPR_PRO"
                            />
                            <AppMenuItem
                              v-if="showESign"
                              :text="
                                $t('fileManagement.fileExplorer.menu.signableDocument')
                              "
                              :icon="$icon.REGULAR.FILE.FILE_SIGNATURE"
                              :disabled="!canCreateSignableDocument(item)"
                              @click="onCreateSignableDocument(item)"
                            />
                          </div>
                        </v-menu>
                      </div>
                    </template>
                  </AppStorageItem>
                </div>
                <v-spacer />
              </template>
              <template v-else>
                <template v-if="!parentIsValid">
                  <v-row
                    class="fill-height w-100 ma-0"
                    align-content="center"
                    justify="center"
                  >
                    <v-col class="subtitle-1 text-center pb-0" cols="12">
                      <v-img
                        contain
                        max-height="200"
                        src="../../assets/gripr_confused_magic.svg"
                      />
                    </v-col>
                    <v-col class="subtitle-1 text-center pb-0" cols="12">
                      <h2>
                        {{ $t("fileManagement.fileExplorer.folderNotFound.title") }}
                      </h2>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row
                    :class="[isDragging ? 'opacity-30' : '']"
                    class="fill-height w-100 ma-0"
                    align-content="center"
                    justify="center"
                  >
                    <v-col class="subtitle-1 text-center pb-0" cols="12">
                      <v-img
                        contain
                        max-height="200"
                        src="../../assets/gripr_confused_magic.svg"
                      />
                    </v-col>
                    <v-col class="subtitle-1 text-center pb-0" cols="12">
                      <h2>
                        {{ $t("fileManagement.fileExplorer.folderEmpty.title") }}
                      </h2>
                    </v-col>
                    <template>
                      <v-col class="subtitle-1 text-center" cols="12" v-if="canUpload">
                        <i18n
                          path="fileManagement.fileExplorer.folderEmpty.subTitle"
                          tag="span"
                        >
                          <template v-slot:folder>
                            <a
                              class="primary-link--text text-lowercase"
                              @click="onItemCreate($constant.FOLDER)"
                            >
                              {{ $t("fileManagement.common.folder") }}
                            </a>
                          </template>
                          <template v-slot:files>
                            <a
                              class="primary-link--text text-lowercase"
                              @click="onItemCreate($constant.FILE)"
                            >
                              {{ $t("fileManagement.common.files") }}
                              <AppSubscriptionPlanStar
                                x-small
                                class="pb-1"
                                :planCode="$constant.PLAN_CODE.GRIPR_PRO"
                                v-if="!planRestrictionsMixins_canUploadToStorage"
                              />
                            </a>
                          </template>
                        </i18n>
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </template>
            </template>
          </div>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            :accept="fileAccepts"
            @change="afterItemCreate($event, $constant.FILE)"
            multiple
          />
        </div>
      </template>
    </AppSideNav>
    <custom-slide-y-reverse-transition>
      <v-toolbar
        v-show="hasSelectedFilesToMerge"
        color="ui-background-black"
        id="mergeActionBar"
        flat
        class="merge-action-bar-absolute"
        height="42"
        width="300"
      >
        <span class="white--text">
          {{ $tc("fileManagement.merge.documentsAdded", selectedFilesToMerge.length) }}
        </span>
        <v-spacer />
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              color="white"
              icon
              @click="dialog.merge.active = true"
            >
              <v-icon>{{ $icon.LIGHT.FILE.FILE_PDF }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("fileManagement.merge.actionMenu.mergeTooltip") }}</span>
        </AppDefaultTooltip>
        <AppDefaultTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" n color="white" icon @click="onClearMerge">
              <v-icon>{{ $icon.LIGHT.ACTION.CLOSE }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("fileManagement.merge.actionMenu.removeTooltip") }}</span>
        </AppDefaultTooltip>
      </v-toolbar>
    </custom-slide-y-reverse-transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";
import _ from "lodash";
import { planRestrictionsMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins, planRestrictionsMixins],
  props: {
    items: Array,
    isLoading: Boolean,
    folderId: String,
    prependPath: Array,
    truncatedPath: Array,
    parent: Object,
    parentIsValid: Boolean,
    parentIsRoot: Boolean,
    searchRootId: String,
    acceptedTypes: Array,
    title: String,
    activeFileId: String,
    isFolderUploading: Boolean,
    isFileUploading: Boolean,
    isDownloading: Boolean,
    canUpload: Boolean,
    canMove: Boolean,
    canLink: Boolean,
    canDownload: Boolean,
    canDelete: Boolean,
    showMove: Boolean,
    showLink: Boolean,
    showDownload: Boolean,
    showDelete: Boolean,
    actionLeft: Boolean,
    actionRight: Boolean,
    boligmappa: {
      type: Object,
    },
    showESign: Boolean,
  },
  data() {
    return {
      isDragging: false,
      tooltipNameLength: 25,
      toolbarType: "DEFAULT",
      showSearch: false,
      fileAccepts: "",
      search: "",
      searchDebounce: null,
      showActionBar: false,
      dialog: {
        deleteConfirmation: {
          active: false,
        },
        folderEdit: {
          active: false,
          data: null,
        },
        fileEdit: {
          active: false,
          data: null,
        },
        folderCreate: {
          active: false,
        },
        link: {
          active: false,
          files: [],
          parentId: null,
        },
        move: {
          active: false,
          permissionLevel: null,
          storage: [],
          parentId: null,
        },
        boligmappaFileUpload: {
          active: false,
          files: [],
        },
        merge: {
          active: false,
        },
        mergeClear: {
          active: false,
        },
      },
    };
  },
  computed: {
    ...mapState("storageMergeToPdf", {
      selectedFilesToMerge: (state) => state.selectedFilesToMerge,
    }),
    ...mapState("wopiDiscovery", {
      wopiDiscovery: (state) => state.discovery,
    }),
    ...mapGetters("auth", {
      domainId: "activeDomainId",
    }),
    hasSelectedFilesToMerge() {
      return this.selectedFilesToMerge.length > 0;
    },
    parentMeta() {
      return this.parent?.meta || [];
    },
    canSelectAll() {
      return this.items.length > 0 ? true : false;
    },
    selectedItems() {
      return this.items.filter((i) => i.isSelected === true);
    },
    lockedStorageSelected() {
      return this.selectedItems.find((i) => i.isLocked) ? true : false;
    },
    isAllSelected() {
      return this.selectedItems.length === this.items.length || false;
    },
    isIndeterminate() {
      return this.selectedItems.length > 0 &&
        this.selectedItems.length !== this.items.length
        ? true
        : false;
    },
    isFolderSelected() {
      return this.selectedItems.find((i) => i.type === this.$constant.FOLDER)
        ? true
        : false;
    },
    localCanDelete() {
      return this.canDelete && !this.lockedStorageSelected;
    },
    showBoligMappa() {
      if (!this.boligmappa) return false;
      if (!Object.values(this.boligmappa).length > 0) return false;
      return true;
    },
    canUploadToBoligmappa() {
      if (this.isFolderSelected) return false;
      if (this.selectedItems.length < 1) return false;
      return true;
    },
    shouldPreventDestruction() {
      return this.hasSelectedFilesToMerge;
    },
    parentId() {
      return this.parent?.id || null;
    },
  },
  watch: {
    selectedItems: {
      handler(selected) {
        if (selected?.length > 0) {
          this.showActionBar = true;
          this.positionActionBar();
        } else {
          this.showActionBar = false;
        }
      },
      deep: true,
    },
    toolbarType: {
      handler(newVal) {
        if (newVal === "SEARCH") {
          this.showSearch = true;
        } else {
          this.showSearch = false;
        }
      },
      immediate: true,
    },
    acceptedTypes: {
      immediate: true,
      handler(types) {
        this.fileAccepts = types?.join(",");
      },
    },
  },
  methods: {
    openInDocumentCenter() {
      let route = this.$router.resolve({
        name: "storageAllFiles",
        params: { rootId: this.domainId, folderId: this.folderId },
      });
      window.open(route.href, "_blank");
    },
    onItemClick(item) {
      const type = item.type;
      this.$emit("item:click", { item, type });
    },
    onItemSelectToggle(item) {
      const type = item.type;
      this.$emit("item:selectToggle", { item, type });
    },
    onSelectAllToggle(value) {
      this.$emit("item:selectAllToggle", { isSelected: value });
    },
    afterItemEdit(item) {
      const type = item.type;
      this.$emit("item:edit", { item, type });
    },
    async afterItemCreate(e, type) {
      switch (type) {
        case this.$constant.FOLDER:
          e.parentId = this.folderId;
          break;
        case this.$constant.FILE:
          e = await this.getFileFormData(e, this.folderId);
          break;
      }
      if (e) {
        this.$emit("item:create", { data: e, type });
      }
    },
    async getFileFormData(e, parentId) {
      if (!this.canUpload) return;
      const files = this.storageMixins_getFiles(e);
      this.$refs.uploader.value = null;
      const { formData } = await this.storageMixins_getFileFormData(files, parentId);

      return formData;
    },
    onItemEdit(item) {
      const key = item.type === this.$constant.FOLDER ? "folderEdit" : "fileEdit";
      this.dialog[key].active = true;
      this.dialog[key].data = item;
    },
    getWopiDiscovery() {
      this.$store.dispatch("wopiDiscovery/getDiscovery");
    },
    onPathItemClick(path) {
      this.$emit("path:click", { path });
    },
    onItemCreate(type) {
      switch (type) {
        case this.$constant.FOLDER:
          this.dialog.folderCreate.active = true;
          this.dialog.folderCreate.data = {
            parent: {
              isRoot: this.isRoot,
              item: this.parent,
            },
          };
          break;
        case this.$constant.FILE:
          if (!this.planRestrictionsMixins_canUploadToStorage) {
            this.openUpgradePlanDialog();
            break;
          }
          this.$refs.uploader.click();
          break;
      }
    },
    openUpgradePlanDialog() {
      this.$store.dispatch("upgradePlanDialog/setUpgradePlanDialog", {
        active: true,
        planCode: this.$constant.PLAN_CODE.GRIPR_PRO,
      });
    },
    onSearchItemClick(item) {
      this.setToolbarType("DEFAULT");
      const type = item.type;
      this.$emit("searchItem:click", { item, type });
    },
    afterSearchItemMove(e) {},
    afterSearchDelete(e) {},
    onMove() {
      const selectedPermissionLevels = this.getSelectedPermissionLevels();
      if (!selectedPermissionLevels.hasSelectedMultiple) {
        const permissionLevel = selectedPermissionLevels.mostSelected;
        this.dialog.move.storage = this.selectedItems;
        this.dialog.move.permissionLevel = permissionLevel;
        this.dialog.move.parentId = this.folderId;
        this.dialog.move.active = true;
      }
    },
    afterMove() {
      this.$emit("move");
    },
    onLink() {
      this.dialog.link.files = this.selectedItems;
      this.dialog.link.parentId = this.folderId;
      this.dialog.link.active = true;
    },
    onBoligmappaFileUpload() {
      if (!this.showBoligMappa) return;
      if (!this.canUploadToBoligmappa) return;
      if (this.isFolderSelected) return;
      if (this.selectedItems.length < 1) return;
      this.dialog.boligmappaFileUpload.files = this.selectedItems;
      this.dialog.boligmappaFileUpload.active = true;
    },
    afterBoligmappaFileUpload() {
      this.$emit("refresh");
    },
    onDownload() {
      this.$emit("item:download", { selectedItems: this.selectedItems });
    },
    onDelete() {
      this.dialog.deleteConfirmation.active = true;
    },
    afterDeleteConfirmation() {
      this.$emit("item:delete", { selectedItems: this.selectedItems });
    },
    setToolbarType(type) {
      switch (type) {
        case "SEARCH":
          this.toolbarType = type;
          break;
        default:
          this.toolbarType = "DEFAULT";
          break;
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave(e) {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.afterItemCreate(e, this.$constant.FILE);
      this.isDragging = false;
    },
    getSelectedPermissionLevels() {
      const permissionLevelCount = _.countBy(
        this.selectedItems.map((x) => x.permissionLevel),
      );
      let count = 0;
      let mostSelected = "";
      if (Object.keys(permissionLevelCount).length > 0) {
        for (const [key, value] of Object.entries(permissionLevelCount)) {
          if (value > 0) {
            count++;
          }
        }

        mostSelected = Object.keys(permissionLevelCount).reduce((a, b) =>
          permissionLevelCount[a] > permissionLevelCount[b] ? a : b,
        );
      }

      return {
        hasSelectedMultiple: count > 1 ? true : false,
        mostSelected,
      };
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.search = event;
      }, 600);
    },
    positionActionBar() {
      setTimeout(() => {
        const parent = document.getElementById("sideNav");
        const child = document.getElementById("actionBar");
        if (parent && child) {
          const parentPos = parent.getBoundingClientRect();
          const relativePos = {
            top: parentPos.top + 140,
            left: parentPos.left + 355,
          };
          if (this.actionLeft) {
            relativePos.top = parentPos.top + 140;
            relativePos.left = parentPos.left - 63;
          } else if (this.actionRight) {
            //keep default position.
          }

          child.style.top = `${relativePos.top}px`;
          child.style.left = `${relativePos.left}px`;
        }
      }, 100);
    },
    positionMergeActionBar() {
      const parent = document.getElementById("storageContainer");
      const child = document.getElementById("mergeActionBar");

      if (parent && child) {
        const parentPos = parent.getBoundingClientRect();
        const relativePos = {
          left: parentPos.width / 2 - 150,
          bottom: 30,
        };

        child.style.left = `${relativePos.left}px`;
        child.style.bottom = `${relativePos.bottom}px`;
      }
    },
    onClearMerge() {
      this.dialog.mergeClear.active = true;
    },
    clearMerge() {
      this.$refs?.mergeToPdfDialog?.resetData();
    },
    onAddToMerge(file) {
      if (!this.planRestrictionsMixins_canMergeFiles) {
        this.openUpgradePlanDialog();
        return;
      }
      if (!this.canMergeFile(file)) return;
      this.$store.commit("storageMergeToPdf/setSelectedFiles", [
        ...this.selectedFilesToMerge,
        file,
      ]);
    },
    onCreateSignableDocument(file) {
      if (!this.canCreateSignableDocument(file)) return;
      this.$emit("item:createSignableDocument", { item: file });
    },
    onSelectedFilesToMergeUpdate(files) {
      this.$store.commit("storageMergeToPdf/setSelectedFiles", files);
    },
    onSelectedFilesRemove(file) {
      this.$store.commit("storageMergeToPdf/removeSelectedFile", file);
    },
    canCreateSignableDocument(file) {
      const isAllowedFileType = this.$constant.TYPES_ALLOWED_TO_SIGN.some(
        (i) => i === file?.type,
      );
      const isAllowedExtension = this.$constant.EXTENSIONS_ALLOWED_TO_SIGN.some(
        (i) => i === file?.extension,
      );
      const meta = file.meta || [];
      const isESignFile = meta.some((m) => m.field === this.$constant.E_SIGN_META_FIELD);
      const allAllowedTypes = [isAllowedFileType, isAllowedExtension];
      const canCreate = allAllowedTypes.every((e) => e === true) && !isESignFile;
      return canCreate;
    },
    canMergeFile(file) {
      const isAllowedFileType = this.$constant.TYPES_ALLOWED_TO_MERGE.some(
        (i) => i === file?.type,
      );
      const isAllowedMimeType = this.$constant.MINE_TYPE_ALLOWED_TO_MERGE.some(
        (i) => i === file?.mime,
      );
      const allAllowedTypes = [isAllowedFileType, isAllowedMimeType];
      const canMerge = allAllowedTypes.every((e) => e === true);
      return canMerge;
    },
    escapeListener(event) {
      if (event.key === "m" && event.ctrlKey) {
        this.onItemCreate(this.$constant.FOLDER);
      }
    },
    beforeUnload(e) {
      if (this.shouldPreventDestruction) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
  },
  created() {
    addEventListener("keydown", this.escapeListener);
    addEventListener("resize", this.positionActionBar);
    addEventListener("resize", this.positionMergeActionBar);
    addEventListener("beforeunload", this.beforeUnload);
  },
  destroyed() {
    removeEventListener("keydown", this.escapeListener);
    removeEventListener("resize", this.positionActionBar);
    removeEventListener("resize", this.positionMergeActionBar);
    removeEventListener("beforeunload", this.beforeUnload);
  },
  mounted() {
    this.positionMergeActionBar();
    if (!this.wopiDiscovery) {
      this.getWopiDiscovery();
    }
  },
};
</script>
