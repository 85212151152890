<template>
  <div>
    <AppModuleAutocomplete
      ref="autocomplete"
      v-bind="params"
      v-on="listeners"
      :items="searchClients"
      :loading="loading"
      item-text="name"
      item-value="id"
      :noDataText="noDataText"
      :titleField="$constant.MODULE_FIELD_CONFIG.CLIENT.TITLE_FIELD"
      :subTitleFields="$constant.MODULE_FIELD_CONFIG.CLIENT.SUB_TITLE_FIELDS"
    >
      <template #append-item>
        <AppSelectAppendCreateBtn @click="onCreateClient">
          {{ $t("common.create") }}
        </AppSelectAppendCreateBtn>
      </template>
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"></slot>
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </AppModuleAutocomplete>
    <AppClientDialog
      v-model="newClientDialog.active"
      :title="$t('contact.client.newClient.title')"
      :confirmText="$t('common.create')"
      @confirm="onClientCreated"
    />
  </div>
</template>

<script>
import { getSearchClients } from "@/services/search/client";
import _ from "lodash";

export default {
  props: {
    value: [String, Array],
    queryParams: Object,
    //no type means both customer and supplier
    type: String,
    isReadyToInitialFetch: Boolean,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      newClientDialog: {
        active: false,
      },
      searchClients: [],
      loading: false,
      //make sure it doesnt fetch again if isReadyToInitialFetch changes
      hasInitialFetched: false,
    };
  },
  watch: {
    isReadyToInitialFetch: {
      handler(newVal) {
        if (newVal && !this.hasInitialFetched) {
          this.getClients().then(() => {
            this.hasInitialFetched = true;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    noDataText() {
      if (this.type === this.$constant.CUSTOMER) {
        return this.$t("common.noCustomers");
      } else if (this.type === this.$constant.SUPPLIER) {
        return this.$t("common.noSuppliers");
      } else {
        return this.$t("common.noClients");
      }
    },
    params() {
      const params = { ...this.$props, ...this.$attrs };
      return params;
    },
    listeners() {
      const listeners = {
        ...this.$listeners,
      };
      return listeners;
    },
  },
  methods: {
    onCreateClient() {
      this.newClientDialog.active = true;
    },
    onClientCreated({ client }) {
      this.getClients().then(() => {
        const searchClient = this.searchClients.find((c) => c.id === client.id);
        if (!searchClient) return;
        this.$emit("created", { client, searchClient });
      });
    },
    async getClients() {
      this.loading = true;
      //Every usage except filter should have "active: true" as param
      await getSearchClients({ params: { ...this.queryParams, type: this.type } })
        .then((clients) => {
          this.searchClients = clients;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    activateMenu() {
      this.$refs.autocomplete.activateMenu();
    },
  },
};
</script>
