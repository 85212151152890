<template>
  <v-dialog v-model="localDialog" scrollable max-width="600px">
    <v-card ref="dialog">
      <v-card-title class="d-flex justify-space-between">
        <span class="headline">
          {{
            $t(
              `timeRegistration.newTimeEntry.${isEditMode ? "editTitle" : "createTitle"}`,
            )
          }}
        </span>
      </v-card-title>
      <v-card-text id="time-entry-dialog-scroll-window">
        <v-form
          ref="newTimeEntryForm"
          v-model="timeEntry.valid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <AppFieldMenuDatePicker
            v-if="!data?.isExtra"
            :label="$t('timeRegistration.newTimeEntry.date')"
            required
            v-model="timeEntry.date.value"
            :rules="timeEntry.date.rules"
            :clearable="false"
          />
          <div class="p-relative" v-if="canSetUser">
            <div class="label--small">
              {{ $t("timeRegistration.newTimeEntry.user") }}
            </div>
            <AppClientGroupedUserAndContactAutocomplete
              v-model="timeEntry.userId.value"
              :items="users"
              :loading="isUsersLoading"
              user-avatar
              :clearable="false"
              :noDataText="$t('common.noUsers')"
              :labelPaths="['workTitleLabel', 'contact.relation.label']"
            />
          </div>
          <AppProjectAutocompleteWithLabel
            v-if="!data?.isExtra && dialog"
            v-model="projectId"
            hide-details
            isReadyToInitialFetch
            :queryParams="{
              alwaysIncludeIds: [projectId],
              mode: 'TIME_ENTRY',
            }"
            type="project"
            @created="({ project }) => (projectId = project.id)"
          />
          <AppTaskAutocompleteWithLabel
            ref="taskAutocomplete"
            v-if="dialog"
            v-model="localTaskId"
            :queryParams="{
              projectId: timeEntry?.projectId.value,
              alwaysIncludeIds: [timeEntry.taskId.value],
              mode: 'TIME_ENTRY',
            }"
            isReadyToInitialFetch
            hide-details
            type="task"
            return-object
            :data="{ projectId: timeEntry.projectId.value }"
            @created="({ searchTask }) => onTaskChange(searchTask)"
          />
          <AppCreateTimeEntryActivityItem
            @timeEntryActivity:change="onTimeEntryActivityChange"
            :hourTypes="hourTypes"
            :isHourTypesLoading="isHourTypesLoading"
            :timeEntry="transformedTimeEntry"
            :fromTimeRules="timeEntry.fromTime.rules"
            :toTimeRules="timeEntry.toTime.rules"
            :isEditMode="isEditMode"
            :isExtra="!!data?.isExtra"
            :dialog="localDialog"
            :projectId="projectId"
          />
          <div
            v-for="(extraTimeEntry, index) in timeEntry.extraTimeEntries.value"
            :key="index"
          >
            <v-divider class="mt-5 mb-4" />
            <AppCreateTimeEntryActivityItem
              @timeEntryActivity:change="onExtraTimeEntryActivityChange($event, index)"
              @delete="onExtraHourTypeDelete(index)"
              :isHourTypesLoading="isHourTypesLoading"
              :hourTypes="hourTypes"
              :timeEntry="extraTimeEntry"
              :fromTimeRules="[]"
              :toTimeRules="[]"
              :isEditMode="isEditMode"
              :dialog="localDialog"
              is-extra
              :showDelete="!extraTimeEntry?.id"
              :projectId="projectId"
            />
          </div>
        </v-form>
        <div
          class="w-100 justify-center d-flex"
          v-if="!data?.isExtra && isTimeEntryAdvancedFeatureEnabled"
        >
          <v-btn
            class="mt-8 text-transform-none"
            depressed
            rounded
            color="secondary"
            @click="addExtraTimeEntry"
          >
            {{ $t("timeRegistration.newTimeEntry.addExtraActivity") }}
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="d-flex flex-column align-start w-100">
          <AppErrorAlert v-if="errorMessage" class="w-100 mb-4">
            {{ errorMessage }}
          </AppErrorAlert>
          <AppDialogActionBtnPair
            @confirm="onSubmit"
            @cancel="close"
            :loading="isSubmitting"
            :confirmText="
              $t(`timeRegistration.newTimeEntry.${isEditMode ? 'editBtn' : 'createBtn'}`)
            "
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getHourTypes } from "@/services/timeEntries/hourTypes";
import { mapGetters } from "vuex";
import moment from "moment";
import { getSearchUsers } from "@/services/search/users";

export default {
  props: {
    dialog: Boolean,
    title: String,
    data: Object,
    defaultProjectId: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {
      isSubmitting: false,
      hourTypes: [],
      isHourTypesLoading: false,
      users: [],
      isUsersLoading: false,
      errorMessage: "",
      timeEntry: {
        valid: false,
        comment: {
          value: null,
        },
        userId: {
          value: null,
        },
        projectId: {
          value: null,
        },
        taskId: {
          value: null,
        },
        hourTypeId: {
          value: null,
        },
        accountingProductId: {
          value: null,
        },
        date: {
          value: null,
          rules: this.$rules.REQUIRED,
        },
        duration: {
          value: null,
        },
        breakDuration: {
          value: null,
          rules: [],
        },
        fromTime: {
          value: null,
          rules: [(v) => this.fromTimeRules(v)],
        },
        toTime: {
          value: null,
          rules: [(v) => this.toTimeRules(v)],
        },
        extraTimeEntries: {
          value: [],
        },
        useTime: {
          value: null,
        },
      },
      permissionLevels: [
        { text: this.$t("common.permissionLevel.public"), value: "PUBLIC" },
        { text: this.$t("common.permissionLevel.restricted"), value: "RESTRICTED" },
        { text: this.$t("common.permissionLevel.private"), value: "PRIVATE" },
      ],
      previewDialiog: {
        active: false,
      },
      newClientDialog: {
        active: false,
      },
    };
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
    ...mapGetters("auth", {
      isTimeEntryAdvancedFeatureEnabled: "isTimeEntryAdvancedFeatureEnabled",
      currentUserId: "currentUserId",
      isDomainOwner: "isDomainOwner",
      isDomainHrAdmin: "isDomainHrAdmin",
    }),
    canSetUser() {
      return this.isDomainOwner || this.isDomainHrAdmin;
    },
    pageHeight() {
      return document.body.scrollHeight;
    },
    transformedTimeEntry() {
      const transformedEntry = {};
      for (const key in this.timeEntry) {
        if (Object.prototype.hasOwnProperty.call(this.timeEntry, key)) {
          const prop = this.timeEntry[key];
          transformedEntry[key] = prop.value !== undefined ? prop.value : prop;
        }
      }

      return transformedEntry;
    },
    isEditMode() {
      return !!this.data;
    },
    projectId: {
      get() {
        return this.timeEntry.projectId.value;
      },
      set(id) {
        this.timeEntry.taskId.value = null;
        if (!!this.timeEntry.projectId.value !== !!id) {
          this.clearHourTypeIds();
        }
        this.timeEntry.projectId.value = id;
      },
    },
    localTaskId: {
      get() {
        return this.timeEntry.taskId.value;
      },
      set(task) {
        this.onTaskChange(task);
      },
    },
    fromTime() {
      return this.timeEntry?.fromTime?.value;
    },
    toTime() {
      return this.timeEntry?.toTime?.value;
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.fillFormWithData();
          this.getInitialData();
        }
      },
    },
    "timeEntry.projectId.value": {
      handler(id) {
        this.$nextTick(() => {
          this.$refs.taskAutocomplete?.getTasks();
        });
      },
    },
  },
  methods: {
    fillFormWithData() {
      const data = this.data;

      this.timeEntry.userId.value = data?.userId ?? this.currentUserId;
      this.timeEntry.date.value = data?.date || new Date().toString();
      this.timeEntry.fromTime.value = data?.fromTime || "07:00";
      this.timeEntry.toTime.value = data?.toTime || "15:00";
      this.timeEntry.duration.value = data?.duration || 28800;

      if (this.defaultProjectId) {
        this.timeEntry.projectId.value = this.defaultProjectId;
      }
      if (!data) return;
      this.timeEntry.extraTimeEntries.value = data?.extraTimeEntries
        ? data.extraTimeEntries.map((extraTimeEntry) => ({
            ...extraTimeEntry,
            fromTime: extraTimeEntry?.fromTime || "07:00",
            toTime: extraTimeEntry?.toTime || "15:00",
            useTime: (extraTimeEntry?.fromTime && extraTimeEntry?.toTime) || false,
          }))
        : [];
      this.timeEntry.hourTypeId.value = data?.hourTypeId || null;
      this.timeEntry.accountingProductId.value = data?.accountingProductId || null;
      this.timeEntry.breakDuration.value = data?.breakDuration || null;
      this.timeEntry.projectId.value = data?.projectId || null;
      this.timeEntry.taskId.value = data?.taskId || null;
      this.timeEntry.comment.value = data?.comment || null;
      this.timeEntry.useTime.value = (data?.fromTime && data?.toTime) || false;
    },
    getInitialData() {
      this.getHourTypes();
      this.getUsers();
    },
    async getHourTypes() {
      this.isHourTypesLoading = true;
      getHourTypes({ isActive: true })
        .then((hourTypes) => {
          this.hourTypes = hourTypes;
        })
        .finally(() => {
          this.isHourTypesLoading = false;
        });
    },
    getUsers() {
      if (!this.canSetUser) return;
      this.isUsersLoading = true;
      const params = {
        projectId: this.timeEntry.projectId?.value,
        alwaysIncludeIds: [this.timeEntry.userId.value],
      };

      getSearchUsers({ params })
        .then((users) => {
          this.users = users;
        })
        .finally(() => {
          this.isUsersLoading = false;
        });
    },
    clearHourTypeIds() {
      this.timeEntry.hourTypeId.value = null;
      for (const extraTimeEntry in this.timeEntry.extraTimeEntries.value) {
        this.timeEntry.extraTimeEntries.value[extraTimeEntry].hourTypeId = null;
      }
    },
    onExtraHourTypeDelete(index) {
      this.timeEntry.extraTimeEntries.value.splice(index, 1);
    },
    onTimeEntryActivityChange({ field, value }) {
      this.timeEntry[field].value = value;
    },
    onExtraTimeEntryActivityChange({ field, value }, index) {
      this.timeEntry.extraTimeEntries.value = this.timeEntry.extraTimeEntries.value.map(
        (entry, i) => {
          if (i === index) {
            return {
              ...entry,
              [field]: value,
            };
          }
          return entry;
        },
      );
    },
    addExtraTimeEntry() {
      this.timeEntry.extraTimeEntries.value = [
        ...JSON.parse(JSON.stringify(this.timeEntry.extraTimeEntries.value)),
        {
          fromTime: this.timeEntry.fromTime.value || "07:00",
          toTime: this.timeEntry.toTime.value || "15:00",
          duration: this.timeEntry.duration.value || 28800,
          comment: null,
          hourTypeId: null,
          accountingProductId: null,
          isExtra: true,
          useTime: this.timeEntry.useTime.value || false,
        },
      ];
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    scrollToBottom() {
      const scrollWindow = document.getElementById("time-entry-dialog-scroll-window");
      scrollWindow.scrollTo({
        top: scrollWindow.scrollHeight,
        behavior: "smooth",
      });
    },
    fromTimeRules(v) {
      return v !== this.timeEntry.toTime.value || this.$t("common.fromAndToCanNotBeZero");
    },
    toTimeRules(v) {
      return v !== this.timeEntry.fromTime.value || "";
    },
    calculateDuration() {
      const fromTime = this.timeEntry?.fromTime?.value;
      const toTime = this.timeEntry?.toTime?.value;
      if (fromTime && toTime) {
        const duration = this.calculateTimeDifferenceInSeconds(fromTime, toTime);
        this.timeEntry.duration.value = duration;
      }
    },
    calculateTimeDifferenceInSeconds(time1, time2) {
      // Split the time strings into hours and minutes
      const [hours1, minutes1] = time1.split(":").map(Number);
      const [hours2, minutes2] = time2.split(":").map(Number);

      // Calculate the total minutes for each time
      const totalMinutes1 = hours1 * 60 + minutes1;
      const totalMinutes2 = hours2 * 60 + minutes2;

      // Calculate the time difference in minutes
      let timeDifferenceMinutes;

      if (totalMinutes2 >= totalMinutes1) {
        timeDifferenceMinutes = totalMinutes2 - totalMinutes1;
      } else {
        timeDifferenceMinutes = totalMinutes2 + (24 * 60 - totalMinutes1);
      }

      // Convert the time difference to seconds
      const timeDifferenceSeconds = timeDifferenceMinutes * 60;

      return timeDifferenceSeconds;
    },
    onTaskChange(task) {
      if (task && this.timeEntry.projectId.value !== task.projectId) {
        if (!!this.timeEntry.projectId.value !== !!task.projectId) {
          this.clearHourTypeIds();
        }
        this.timeEntry.projectId.value = task.projectId;
      }
      this.timeEntry.taskId.value = task.id;
    },
    createOrUpdateTimeEntry(data) {
      const storeType = this.isEditMode
        ? "timeEntries/updateTimeEntry"
        : "timeEntries/createTimeEntry";
      this.isSubmitting = true;
      this.errorMessage = "";
      this.$store
        .dispatch(storeType, data)
        .then(() => {
          this.afterSubmit();
        })
        .catch((error) => {
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    afterSubmit() {
      this.$emit("confirm");
      this.localDialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$refs.newTimeEntryForm.resetValidation();
      this.timeEntry.extraTimeEntries.value = [];
      this.timeEntry.hourTypeId.value = null;
      this.timeEntry.accountingProductId.value = null;
      this.timeEntry.duration.value = null;
      this.timeEntry.breakDuration.value = null;
      this.timeEntry.date.value = new Date().toString();
      this.timeEntry.projectId.value = null;
      this.timeEntry.taskId.value = null;
      this.timeEntry.comment.value = null;
      this.timeEntry.fromTime.value = "07:00";
      this.timeEntry.toTime.value = "15:00";
      this.timeEntry.useTime.value = null;
      this.timeEntry.userId.value = this.currentUserId;
    },

    formatBody(timeEntryToFormat) {
      const useTime = timeEntryToFormat.useTime;
      const hasExtraTimeEntries = timeEntryToFormat.extraTimeEntries?.length > 0;
      const extraTimeEntries = hasExtraTimeEntries
        ? timeEntryToFormat.extraTimeEntries.map(this.formatBody)
        : [];

      const date = timeEntryToFormat.date
        ? moment(timeEntryToFormat.date).format("YYYY-MM-DD")
        : null;

      let fields = {};

      if (!useTime) {
        fields = { breakDuration: null, fromTime: null, toTime: null };
      }

      const body = {
        ...timeEntryToFormat,
        ...(date && { date }),
        ...fields,
        ...(hasExtraTimeEntries && { extraTimeEntries }),
        useTime: undefined,
      };

      return body;
    },

    onSubmit() {
      const valid = this.$refs.newTimeEntryForm.validate();
      if (valid) {
        const data = {
          id: this.data?.id,
          body: this.formatBody(this.transformedTimeEntry),
        };
        this.createOrUpdateTimeEntry(data);
      }
    },
    close() {
      this.errorMessage = null;
      this.localDialog = false;
      this.$refs.newTimeEntryForm.resetValidation();
    },
  },
};
</script>
