<template>
  <v-dialog v-model="localDialog" max-width="600px" scrollable>
    <v-card :disabled="isCreating">
      <v-card-title>
        <span class="text-h5 font-weight-bold">
          {{ $t("settings.workspaces.createWorkspace.title") }}
        </span>
      </v-card-title>
      <v-card-text class="primary-text--text">
        <v-form
          ref="createWorkspaceForm"
          v-model="form.isValid"
          v-on:submit.prevent
          @submit="onSubmit"
        >
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.workspaces.workspaceCommon.companyName") }}
            </div>
            <AppOrganizationLookupAutocomplete
              v-model="organization"
              :rules="$rules.REQUIRED"
              autofocus
              dense
              :placeholder="$t('common.search')"
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.workspaces.workspaceCommon.companyAddress") }}
            </div>
            <AppAddressCombobox
              ref="address"
              :value="form.address.value"
              @change="onAddressChange"
              :rules="$rules.REQUIRED"
              :hide-details="false"
              :dense="true"
            />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("settings.workspaces.workspaceCommon.numberOfEmployees") }}
            </div>
            <AppDefaultTextField
              v-model="form.employees.value"
              :rules="form.employees.rules"
              type="number"
              :dense="true"
              @keydown="numberMixins_preventNonNumericInput"
            />
          </div>
          <div class="p-relative">
            <div class="label--small">
              {{ $t("settings.workspaces.workspaceCommon.language") }}
            </div>
            <AppLanguageSelect v-model="form.language.value" :rules="$rules.REQUIRED" />
          </div>
          <div class="p-relative">
            <div class="label--small required-asterisk">
              {{ $t("common.industries") }}
            </div>
            <AppDefaultAutocomplete
              :items="industries"
              v-model="form.industries.value"
              :rules="form.industries.rules"
              :loading="isLoadingIndustries"
              item-value="value"
              item-text="text"
              multiple
              clearable
            />
          </div>
          <div class="p-relative">
            <AppAcceptTermsCheckbox
              v-model="form.acceptedTermsAndPrivacy.value"
              :rules="form.acceptedTermsAndPrivacy.rules"
            />
          </div>

          <AppErrorAlert v-if="hasError">
            {{ errorMessage || $t("common.defaultErrorMessage") }}
          </AppErrorAlert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <AppDialogActionBtnPair
          @confirm="onSubmit"
          @cancel="onClose"
          :confirmText="$t('settings.workspaces.workspaceCommon.createButton')"
          :loading="isCreating"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { signupEvent } from "../../../helpers/util";
import { createDomain } from "../../../services/signup/signupService";
import { getIndustries } from "@/services/resources/industries";
import { numberMixins } from "@/helpers/mixins";

export default {
  mixins: [numberMixins],
  props: {
    dialog: Boolean,
    deleteBtnText: String,
  },
  data() {
    return {
      form: {
        isValid: false,
        companyName: {
          value: null,
        },
        orgnr: {
          value: null,
        },
        language: {
          value: "nb",
        },
        address: {
          value: "",
        },
        employees: {
          value: "",
          rules: [...this.$rules.REQUIRED],
        },
        website: {
          value: "",
          rules: [],
        },
        industries: {
          value: [],
          rules: this.$rules.REQUIRED,
        },
        acceptedTermsAndPrivacy: {
          value: false,
          rules: this.$rules.REQUIRED,
        },
      },
      isCreating: false,
      industries: [],
      isLoadingIndustries: false,
      hasError: false,
      errorMessage: "",
    };
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  computed: {
    organization: {
      get() {
        return {
          name: this.form.companyName.value,
          orgnr: this.form.orgnr.value,
        };
      },
      set(item) {
        if (!this.form.employees.value) {
          this.form.employees.value = item?.numberOfEmployees ?? "";
        }
        this.form.companyName.value = item?.name ?? "";
        this.form.website.value = item?.website ?? null;
        this.form.orgnr.value = item?.orgnr ?? null;

        if (item?.fullAddress) {
          this.form.address.value = {
            address: item.address,
            place: item.place,
            postalNr: item.postalNr,
            text: item.fullAddress,
          };
        }
      },
    },

    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  watch: {
    dialog: {
      handler(dialog) {
        if (dialog) {
          this.getIndustries();
        }
      },
    },
  },
  methods: {
    onSubmit() {
      const isValid = this.$refs.createWorkspaceForm.validate();
      if (!isValid) return;
      this.isCreating = true;
      this.createWorkspace();
    },
    async createWorkspace() {
      this.hasError = false;
      const domainBody = this.createBody();
      await createDomain(domainBody)
        .then(() => {
          this.triggerSnackbar(
            this.$t("cms.createWorkspace.successfullyCreatedWorkspace"),
            "success",
          );

          signupEvent({ orgnr: domainBody.orgnr });
          this.onWorkspaceCreated();
        })
        .catch(({ response }) => {
          const text = response?.data?.message || "Something went wrong...";
          this.triggerSnackbar(text, "error");
          this.hasError = true;
          this.errorMessage = text;
        })
        .finally(() => {
          this.isCreating = false;
        });
    },
    createBody() {
      let address;
      if (typeof this.form.address.value === "object") {
        address = {
          place: this.form.address.value.place,
          address: this.form.address.value.address,
          zip: this.form.address.value.postalNr,
        };
      } else {
        address = {
          address: this.form.address.value,
          place: null,
          zip: null,
        };
      }
      const body = {
        name: this.form.companyName.value,
        orgnr: this.form.orgnr.value,
        language: this.form.language.value,
        employees: parseInt(this.form.employees.value) ?? null,
        website: this.form.website.value,
        industries: this.form.industries.value,
        acceptedTermsAndPrivacy: this.form.acceptedTermsAndPrivacy.value,
        ...address,
      };

      return body;
    },
    triggerSnackbar(text, color) {
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text,
        color,
      });
    },
    closeDialog() {
      this.localDialog = false;
    },
    onClose() {
      this.closeDialog();
      this.$refs.createWorkspaceForm.resetValidation();
    },
    onWorkspaceCreated() {
      this.$refs.createWorkspaceForm.reset();
      this.closeDialog();
      this.$emit("workspace:created");
    },
    onAddressChange(location) {
      const value =
        location !== null && typeof location === "object" ? location.text : location;
      this.form.address.value = value;
    },
    async getIndustries() {
      this.isLoadingIndustries = true;
      getIndustries()
        .then((industries) => {
          this.industries = industries;
        })
        .finally(() => {
          this.isLoadingIndustries = false;
        });
    },
  },
};
</script>
@/services/signup/industries @/services/resources/industries
