import axios from "axios";
import FileSaver from "file-saver";
import _ from "lodash";
import { v4 } from "uuid";
import {
  normalizePathData,
  normalizeStorage,
  normalizeStorages,
} from "@/helpers/normalization";
const pathTrackId = v4();

const apiUrl = process.env.VUE_APP_API_URL;
const integrationApiUrl = process.env.VUE_APP_INTEGRATION_API_URL;

const state = () => ({
  items: [],
  parent: {
    item: null,
    isRoot: false,
    canUpload: false,
    isLoading: false,
  },
  path: [],
  truncatedPath: [],
  prependPath: [],
  isItemsLoading: false,
  isPathLoading: false,
  isDownloading: false,
  isDeleting: false,
  file: {
    isUploading: false,
    acceptedTypes: [],
  },
  folder: {
    isUploading: false,
  },
});

const getters = {
  isLoading: (state) => {
    return state.isPathLoading || state.isItemsLoading || state.parent.isLoading;
  },
  parentIsValid: (state) => {
    return state.parent.item || state.parent.isRoot ? true : false;
  },
  canUpload: (state, getters) => {
    return getters.parentIsValid && !getters.isLoading && state.parent.canUpload;
  },
  selectedItems: (state) => {
    return state.items.filter((i) => i.isSelected === true);
  },
  isAllSelected: (state, getters) => {
    return state.items.length > 0
      ? getters.selectedItems.length === state.items.length
      : false;
  },
  isIndeterminate: (state, getters) => {
    return getters.selectedItems.length > 0 &&
      getters.selectedItems.length !== state.items.length
      ? true
      : false;
  },
  folderSelected: (state, getters) => {
    return getters.selectedItems.find((i) => i.type === "FOLDER") ? true : false;
  },
  relationFileSelected: (state, getters) => {
    return getters.selectedItems.find((i) => i.type === "FILE_RELATION") ? true : false;
  },
  lockedStorageSelected: (state, getters) => {
    return getters.selectedItems.find((i) => i.isLocked) ? true : false;
  },
  selectedPermissionLevels: (state, getters) => {
    const permissionLevelCount = _.countBy(
      getters.selectedItems.map((x) => x.permissionLevel),
    );
    let mostSelected = "";
    let count = 0;
    if (Object.keys(permissionLevelCount).length > 0) {
      for (const [key, value] of Object.entries(permissionLevelCount)) {
        if (value > 0) {
          count++;
        }
      }

      mostSelected = Object.keys(permissionLevelCount).reduce((a, b) =>
        permissionLevelCount[a] > permissionLevelCount[b] ? a : b,
      );
    }

    return {
      hasSelectedMultiple: count > 1 ? true : false,
      mostSelected,
    };
  },
};

const actions = {
  async getStorage({ commit }, id) {
    commit("storageLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/children`)
      .then(({ data }) => {
        const storages = data?.storages;
        const normalizedStorages = normalizeStorages(storages);
        commit("setStorageItems", normalizedStorages);
        commit("storageLoading", false);
      })
      .catch((error) => {
        commit("storageLoading", false);
        throw new Error(error);
      });
  },
  async getParent({ commit, dispatch }, id) {
    commit("parentLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}`, { skipErrorHandling: true })
      .then(({ data }) => {
        commit("setParentItem", data.storage);
        commit("setParentIsRoot", data.isRoot);
        commit("setParentCanUpload", data.canUpload);
        commit("parentLoading", false);
      })
      .catch((error) => {
        commit("parentLoading", false);
        dispatch(
          "snackbar/snackbar",
          {
            show: true,
            text: "Folder not found",
            timeout: 2500,
            color: "error",
          },
          { root: true },
        );
        throw new Error(error);
      });
  },
  async getStoragePath({ commit }, { id, rootId }) {
    commit("storagePathLoading", true);
    return axios
      .get(`${apiUrl}storage/folder/${id}/path`, {
        params: { rootId },
        headers: { trackId: pathTrackId },
      })
      .then(({ data }) => {
        const { path, prependPath, truncatedPath } = normalizePathData(data?.path || []);

        commit("setPrependPath", prependPath);
        commit("setStoragePath", path);
        commit("setTruncatedStoragePath", truncatedPath);
        commit("storagePathLoading", false);
      })
      .catch((error) => {
        commit("storagePathLoading", false);
        throw new Error(error);
      });
  },
  async deleteStorageItems({ commit, dispatch }, { parentId, items }) {
    commit("deleting", true);
    for (let item of items) {
      commit("toggleStorageLoading", { storage: item, isLoading: true });
    }
    const ids = items.map((i) => i.id);
    return axios
      .delete(`${apiUrl}storage`, { data: { ids } })
      .then((res) => {
        dispatch("getStorage", parentId);
      })
      .catch((error) => {
        for (let item of items) {
          commit("toggleStorageLoading", { storage: item, isLoading: false });
        }
        throw new Error(error);
      })
      .finally(() => {
        commit("deleting", false);
      });
  },
  async createFolder({ commit, dispatch }, folder) {
    commit("uploading", { type: "FOLDER", isUploading: true });
    return axios
      .post(`${apiUrl}storage/folder`, {
        ...folder,
      })
      .then((res) => {
        commit("uploading", { type: "FOLDER", isUploading: false });
        dispatch("getStorage", folder.parentId);
      })
      .catch((error) => {
        commit("uploading", { type: "FOLDER", isUploading: false });
        throw new Error(error);
      });
  },
  async updateFolder({ commit }, folder) {
    commit("toggleStorageLoading", { storage: folder, isLoading: true });

    return axios
      .put(`${apiUrl}storage/folder/${folder.id}`, {
        name: folder.name,
      })
      .then((res) => {
        commit("toggleStorageLoading", { storage: folder, isLoading: false });
        commit("updateStorageItem", res.data.storage);
      })
      .catch((error) => {
        commit("toggleStorageLoading", { storage: folder, isLoading: false });
        throw new Error(error);
      });
  },
  async uploadFiles({ commit, dispatch }, { parentId, formData }) {
    commit("uploading", { type: "FILE", isUploading: true });
    return axios
      .post(`${apiUrl}storage/file`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch("getStorage", parentId);
        commit("uploading", { type: "FILE", isUploading: false });
      })
      .catch((error) => {
        commit("uploading", { type: "FILE", isUploading: false });
        throw new Error(error);
      });
  },
  async copyItems({ dispatch }, { body, parentId }) {
    return axios
      .post(`${apiUrl}storage/copy`, body)
      .then(({ storages }) => {
        dispatch("getStorage", parentId);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  async createEmptyFile({ commit, state }, { body }) {
    commit("uploading", { type: "FILE", isUploading: true });
    return axios
      .post(`${apiUrl}storage/empty-file`, body)
      .then(({ data }) => {
        const storage = data?.storage;
        if (storage) {
          const normalizedStorage = normalizeStorage(storage);
          commit("setStorageItems", [...state.items, normalizedStorage]);
          return normalizedStorage;
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        commit("uploading", { type: "FILE", isUploading: false });
      });
  },
  async updateFile({ commit }, file) {
    commit("toggleStorageLoading", { storage: file, isLoading: true });
    return axios
      .put(`${apiUrl}storage/file/${file.id}`, {
        name: file.name,
      })
      .then((res) => {
        if (file.type === "FILE_RELATION") {
          commit("updateStorageItem", { ...res.data.storage, id: file.id });
        } else {
          commit("updateStorageItem", res.data.storage);
        }
        commit("toggleStorageLoading", { storage: file, isLoading: false });
      })
      .catch((error) => {
        commit("toggleStorageLoading", { storage: file, isLoading: false });
        throw new Error(error);
      });
  },
  async download({ commit }, ids) {
    commit("downloading", true);
    return axios
      .get(`${apiUrl}storage/download`, {
        params: { ids },
        responseType: "blob",
      })
      .then((res) => {
        commit("downloading", false);
        let fileName = "";
        const contentDisposition = res.headers["content-disposition"];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
        FileSaver.saveAs(res.data, fileName);
      })
      .catch((error) => {
        commit("downloading", false);
        throw new Error(error);
      });
  },
  async getAcceptedFileTypes({ commit }) {
    return axios.get(`${apiUrl}storage/accepted-mime`).then(({ data }) => {
      const acceptedTypesToConvert = ["image/heic", "image/heif"];
      const types = [...data.mimeTypes, ...acceptedTypesToConvert];
      commit("setAcceptedFileTypes", types);
    });
  },
  async createSignableDocument({ dispatch }, { parentId, storageId }) {
    return axios
      .post(`${integrationApiUrl}e-sign/signable-document`, { storageId })
      .then(({ data }) => {
        dispatch("getStorage", parentId);
        return data.storage;
      });
  },
  resetState({ commit }) {
    commit("setStorageItems", []);
    commit("setParentItem", null);
    commit("setParentIsRoot", false);
    commit("setParentCanUpload", false);
    commit("setStoragePath", []);
    commit("setTruncatedStoragePath", []);
    commit("setPrependPath", []);
    commit("storageLoading", false);
    commit("parentLoading", false);
    commit("storagePathLoading", false);
  },
};

const mutations = {
  setStorageItems(state, items) {
    state.items = items;
  },
  setParentItem(state, item) {
    state.parent.item = item;
  },
  setParentIsRoot(state, isRoot) {
    state.parent.isRoot = isRoot;
  },
  setParentCanUpload(state, canUpload) {
    state.parent.canUpload = canUpload;
  },
  updateStorageItem(state, item) {
    if (!item) throw new Error("No folder");
    const index = state.items.findIndex((x) => x.id === item.id);
    if (index !== -1) {
      state.items[index].name = item.name;
    }
  },
  setStoragePath(state, path) {
    state.path = path;
  },
  setTruncatedStoragePath(state, path) {
    state.truncatedPath = path;
  },
  setPrependPath(state, path) {
    state.prependPath = path;
  },
  setAcceptedFileTypes(state, types) {
    state.file.acceptedTypes = types;
  },
  storageLoading(state, isLoading) {
    state.isItemsLoading = isLoading;
  },
  parentLoading(state, isLoading) {
    state.parent.isLoading = isLoading;
  },
  storagePathLoading(state, isLoading) {
    state.isPathLoading = isLoading;
  },
  uploading(state, { type, isUploading }) {
    switch (type) {
      case "FOLDER":
        state.folder.isUploading = isUploading;
        break;
      case "FILE":
        state.file.isUploading = isUploading;
        break;
    }
  },
  downloading(state, isDownloading) {
    state.isDownloading = isDownloading;
  },
  deleting(state, isDeleting) {
    state.isDeleting = isDeleting;
  },
  toggleStorageLoading(state, payload = { storage: null, isLoading: false }) {
    if (!payload.storage) throw new Error("No folder");
    const index = state.items.findIndex((x) => x.id === payload.storage.id);
    if (index !== -1) {
      state.items[index].isLoading = payload.isLoading;
    }
  },
  toggleStorageSelected(state, { items, isSelected }) {
    for (const item of items) {
      const index = state.items.findIndex((x) => x.id == item.id);

      if (index !== -1) {
        state.items[index].isSelected = isSelected;
      }
    }
  },
};

export const storage = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
