<template>
  <v-dialog v-model="localDialog" max-width="600px">
    <template v-for="integration in $constant.AUTH_WITH_CODE_INTEGRATIONS">
      <template v-if="integrationName === integration.integrationName">
        <ConfigWithCode v-bind="integration" @cancel="cancel" @complete="complete" />
      </template>
    </template>
    <template v-for="integration in $constant.AUTH_WITH_EMAIL_PASS_INTEGRATIONS">
      <template v-if="integrationName === integration.integrationName">
        <ConfigWithEmailPass v-bind="integration" @cancel="cancel" @complete="complete" />
      </template>
    </template>
  </v-dialog>
</template>

<script>
import ConfigWithEmailPass from "./DialogContent/ConfigWithEmailPass.vue";
import ConfigWithCode from "./DialogContent/ConfigWithCode.vue";

export default {
  components: { ConfigWithEmailPass, ConfigWithCode },
  props: {
    dialog: Boolean,
    integrationName: String,
  },
  model: {
    prop: "dialog",
    event: "dialog:change",
  },
  data() {
    return {};
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog:change", value);
      },
    },
  },
  methods: {
    cancel() {
      this.localDialog = false;
    },
    complete() {
      this.localDialog = false;
      this.$emit("complete", { integrationName: this.integrationName });
    },
  },
};
</script>
