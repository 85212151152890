import _ from "lodash";

export const setupQueryParamPreservation = (router, configurations) => {
  configurations.forEach((config) => {
    const PRESERVE_QUERY_PARAMS = config.preserveParams;
    const PARENT_ROUTE_NAMES = _.castArray(config.parentRouteNames);

    let pendingQuery = null;

    router.beforeEach((to, from, next) => {
      pendingQuery = clearPreservedParamsIfNeeded(
        to,
        from,
        PARENT_ROUTE_NAMES,
        PRESERVE_QUERY_PARAMS,
      );
      next();
    });

    router.afterEach((to, from) => {
      updateQueryParamsIfNeeded(router, to, pendingQuery);
    });
  });
};

const clearPreservedParamsIfNeeded = (
  to,
  from,
  parentRouteNames,
  preserveQueryParams,
) => {
  if (isWithinSameParentRoute(to, from, parentRouteNames)) {
    return getUpdatedQuery(to, from, preserveQueryParams);
  }
  return null;
};

const isWithinSameParentRoute = (to, from, parentRouteNames) => {
  const toParent = getParentRouteName(to, parentRouteNames);
  const fromParent = getParentRouteName(from, parentRouteNames);
  const isWithinParentRoutes = toParent && fromParent && toParent === fromParent;

  return isWithinParentRoutes;
};

const getParentRouteName = (route, parentRouteNames) => {
  const matchedParent = route.matched.find((record) =>
    parentRouteNames.includes(record.name),
  );
  return matchedParent ? matchedParent.name : null;
};

const updateQueryParamsIfNeeded = (router, to, pendingQuery) => {
  if (pendingQuery && !_.isEqual(to.query, pendingQuery)) {
    router.replace({ query: pendingQuery }).catch((err) => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
  }
};

const getUpdatedQuery = (to, from, preserveQueryParams) => {
  const updatedQuery = { ...to.query };
  preserveQueryParams.forEach((param) => {
    if (from.query[param] && !to.query[param]) {
      updatedQuery[param] = from.query[param];
    }
  });
  return updatedQuery;
};
