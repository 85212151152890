<template>
  <v-card :disabled="isLoading">
    <v-card-title class="d-flex">
      <span>
        {{ $t(`settings.integrations.${integrationName}.settingsDialog.title`) }}
      </span>
      <v-spacer />
      <AppStonlyGuideBtn :guideId="getGuideId('title')" />
    </v-card-title>
    <v-card-text class="primary-text--text">
      {{ $t(`settings.integrations.${integrationName}.settingsDialog.subTitle`) }}
      <div class="d-flex flex-column w-100">
        <div class="pt-6">
          <template
            v-for="(
              integrationNames, type
            ) in $constant.INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES"
          >
            <template v-if="isImportIntegrationType(integrationNames)">
              <IntegrationInstanceSettingsSwitchItem
                :active="getIsActiveByType(type)"
                :text="$t(`settings.integrations.common.settingsDialog.${type}`)"
                :guideId="getGuideId(type)"
                @switch:toggle="onSettingsItemToggle($event, type)"
              />
              <v-divider class="mt-2" />
            </template>
          </template>
        </div>
        <IntegrationInstanceSettingsSwitchItem
          :active="getIsActiveByType($constant.IMPORT_SETTINGS.UPDATE_IMPORTED_DATA)"
          :text="
            $t(
              `settings.integrations.common.settingsDialog.${$constant.IMPORT_SETTINGS.UPDATE_IMPORTED_DATA}`,
            )
          "
          :guideId="getGuideId($constant.IMPORT_SETTINGS.UPDATE_IMPORTED_DATA)"
          @switch:toggle="
            onSettingsItemToggle($event, $constant.IMPORT_SETTINGS.UPDATE_IMPORTED_DATA)
          "
        />
        <template v-if="isExternalAccountRequired">
          <div class="pt-7">
            <v-form
              ref="externalAccountsForm"
              v-model="externalAccounts.isValid"
              @submit="complete"
              v-on:submit.prevent
            >
              <div class="label--small required-asterisk">Foretak/business</div>
              <AppDefaultAutocomplete
                :items="externalAccounts.items"
                v-model="externalAccounts.value"
                :rules="$rules.REQUIRED"
                item-text="name"
                item-value="externalAccountId"
                :clearable="false"
                :loading="externalAccounts.isLoading"
              >
                <template v-slot:item="{ item: organization, on, attrs }">
                  <AppOrganizationListItem
                    :organization="organization"
                    :isSelected="isSelected(organization)"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
              </AppDefaultAutocomplete>
            </v-form>
          </div>
        </template>
        <AppErrorAlert v-if="showError" class="mt-4">
          {{ $t("common.defaultErrorMessage") }}
        </AppErrorAlert>
      </div>
    </v-card-text>
    <v-card-actions>
      <AppDialogActionBtnPair @confirm="complete" @cancel="cancel" :loading="isLoading" />
    </v-card-actions>
  </v-card>
</template>

<script>
import IntegrationInstanceSettingsSwitchItem from "./IntegrationInstanceSettingsSwitchItem.vue";
import { getExternalAccounts } from "@/services/integration/accounting/accounting.service";

export default {
  components: { IntegrationInstanceSettingsSwitchItem },
  props: {
    integration: Object,
  },
  data() {
    return {
      localSynchronizationStatuses: [],
      localImportSettings: [],

      externalAccounts: {
        value: null,
        items: [],
        isLoading: false,
        isValid: false,
      },

      isLoading: false,

      showError: false,
      stonlyGuides: {
        TRIPLETEX: {
          title: "mhiswajvGe",
          USER: "H6GzqQ5kjM",
          CLIENT: "iumwLe4rMb",
          CONTACT: "ylCmuDkkNk",
          PROJECT: "KDJgp3IqVw",
          PRODUCT: "YahoxRCQdw",
          UPDATE_IMPORTED_DATA: "N9rLg8mbsD",
        },
        POWEROFFICE: {
          title: "9SkNGT62Ij",
          USER: "PPRsRAYqJ3",
          CLIENT: "0pmReXuOWC",
          CONTACT: "ylCmuDkkNk",
          PROJECT: "KhYntc3sZs",
          PRODUCT: "YahoxRCQdw",
          UPDATE_IMPORTED_DATA: "ZaTOoqE26a",
        },
      },
    };
  },
  computed: {
    synchronizationStatuses() {
      return this.integration?.integrationInstance?.synchronizationStatuses || [];
    },
    importSettings() {
      return this.integration?.integrationInstance?.importSettings || [];
    },
    integrationName() {
      return this.integration?.name;
    },
    isExternalAccountRequired() {
      return this.$constant.EXTERNAL_ACCOUNT_REQUIRED_INTEGRATIONS.includes(
        this.integrationName,
      );
    },
  },
  methods: {
    isImportIntegrationType(integrationNames) {
      return integrationNames.includes(this.integrationName);
    },
    isSelected(organization) {
      const isSelected = this.externalAccounts.value === organization.externalAccountId;
      return isSelected;
    },
    async getExternalAccounts() {
      this.externalAccounts.isLoading = true;
      const { accounts } = await getExternalAccounts();

      const filteredAccounts = accounts.filter(
        (account) => account.integrationId === this.integration.name,
      );
      this.externalAccounts.items = filteredAccounts;
      this.externalAccounts.isLoading = false;

      if (!this.externalAccounts.value && this.externalAccounts.items.length === 1) {
        this.externalAccounts.value = this.externalAccounts.items[0].externalAccountId;
      }
    },
    getGuideId(key) {
      if (!this.stonlyGuides[this.integrationName]) return "";
      return this.stonlyGuides[this.integrationName][key] || "";
    },
    getIsActiveByType(type) {
      const key = this.getKey(type);
      const status = this[key]?.find((status) => status.type === type);
      return status?.active;
    },
    getKey(type) {
      let key = null;
      if (type in this.$constant.INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES)
        key = "synchronizationStatuses";
      if (type in this.$constant.IMPORT_SETTINGS) key = "importSettings";
      return key;
    },
    onSettingsItemToggle(e, type) {
      if (type in this.$constant.INTEGRATION_NAMES_SUPPORTING_IMPORT_TYPES) {
        this.setLocalData("localSynchronizationStatuses", type, e);
      } else if (type in this.$constant.IMPORT_SETTINGS) {
        this.setLocalData("localImportSettings", type, e);
      }
    },
    setLocalData(localDataName, type, e) {
      for (let i in this[localDataName]) {
        if (this[localDataName][i].type === type) {
          const newStatus = { type, active: e };
          this[localDataName][i] = newStatus;
        }
      }
    },
    async onUpdateSynchronizationStatuses() {
      await this.$store.dispatch("integrations/updateInstance", {
        id: this.integration?.integrationInstance.id,
        body: {
          synchronizationStatuses: this.localSynchronizationStatuses,
          importSettings: this.localImportSettings,
          settings: {
            ...(this.isExternalAccountRequired && {
              externalAccountId: this.externalAccounts.value,
            }),
          },
        },
      });
    },
    cancel() {
      this.$emit("cancel");
      this.showError = false;
    },
    validate() {
      if (!this.isExternalAccountRequired) return true;
      const valid = this.$refs.externalAccountsForm.validate();
      return valid;
    },
    async complete() {
      const valid = this.validate();
      if (!valid) return;

      this.isLoading = true;
      this.showError = false;
      this.onUpdateSynchronizationStatuses()
        .then(() => {
          this.$emit("complete");
        })
        .catch((err) => {
          this.showError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    this.localSynchronizationStatuses = [...this.synchronizationStatuses];
    this.localImportSettings = [...this.importSettings];

    if (this.isExternalAccountRequired) {
      this.externalAccounts.value =
        this.integration?.integrationInstance?.settings?.externalAccountId;

      this.getExternalAccounts();
    }
  },
};
</script>
