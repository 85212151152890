<template>
  <div>
    <v-menu maxHeight="400" offset-y>
      <template v-slot:activator="{ on }">
        <AppDefaultTextField
          style="width: 250px"
          v-model="search"
          :label="$t('fileManagement.common.search')"
          :append-icon="$icon.LIGHT.ACTION.SEARCH"
          hide-details
          clearable
          backgroundColor="white"
          v-on="on"
        />
      </template>
      <div class="pa-2 ui-background d-flex flex-column" style="min-width: 70px">
        <v-list v-if="results.length === 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("fileManagement.common.noResults") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <AppMenuItem
          v-for="item in results"
          :key="item.id"
          :text="item.name"
          @click="onResultClick(item)"
        >
          <template v-slot:prepend-inner>
            <AppStorageThumbnail class="mr-3" :storage="item" small />
          </template>
        </AppMenuItem>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  data() {
    return {
      search: "",
    };
  },
  props: {
    rootId: String,
  },
  computed: {
    ...mapState("storageSearch", {
      results: (state) => state.items,
      isLoading: (state) => state.isLoading,
    }),
  },
  watch: {
    search: {
      handler(search) {
        this.debounceSearch(search);
      },
    },
  },
  methods: {
    getSearch(search) {
      this.$store.dispatch("storageSearch/search", {
        search,
        rootId: this.rootId,
      });
    },
    onResultClick(item) {
      this.$emit("search:click", { item });
    },
    debounceSearch(event) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.getSearch(event);
      }, 600);
    },
  },
};
</script>
