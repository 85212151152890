<template>
  <v-icon :size="iconSize" :class="{ 'text--disabled': disabled }">
    {{ storageMixins_getStorageIconName(storage) }}
  </v-icon>
</template>

<script>
import { storageMixins } from "@/helpers/mixins";

export default {
  mixins: [storageMixins],
  props: {
    storage: {
      type: Object,
      default: null,
    },
    disabled: Boolean,
    large: Boolean,
    small: Boolean,
  },
  computed: {
    iconSize() {
      if (this.small) return 18;
      if (this.large) return 36;
      return 24;
    },
  },
};
</script>
