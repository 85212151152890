<script>
import {
  VBtn,
  VDatePicker,
  VIcon,
  VList,
  VListItem,
  VListItemTitle,
  VMenu,
  VCard,
} from "vuetify/lib";
import moment from "moment";
import AppUserAvatarTooltip from "../../user/AppUserAvatarTooltip.vue";
import { dateMixins } from "@/helpers/mixins";

export default {
  mixins: [dateMixins],
  props: {
    item: Object,
    assignableUsers: Array,
  },
  methods: {
    onTaskUpdate(e, field, task) {
      const value =
        typeof e === "object" && e !== null && e !== undefined
          ? e?.target?.value
            ? e?.target?.value.trim()
            : null
          : e;

      this.$emit("item:taskUpdate", { item: this.item, task, body: { [field]: value } });
    },
    onTaskNameChangeEnter(task) {
      setTimeout(() => {
        document.getElementById(`itemTaskTextField_${task?.id}`)?.blur();
      }, 100);
    },
    onStatusChange({ statusId, selectedItem }) {
      this.onTaskUpdate(statusId, "statusId", selectedItem);
    },
    onStatusClick({ pointerEvent, x, y, selectedItem }) {
      if (!selectedItem || selectedItem?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({
        pointerEvent,
        x,
        y,
        data: { statusId: selectedItem?.statusId, selectedItem },
      });
    },
    formatDueDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "";
    },
    genNameElm(task) {
      return this.$createElement("div", { class: "item-task__element w-100" }, [
        this.$createElement("AppCustomStatusIcon", {
          on: {
            click: (e) => this.onStatusClick({ ...e, selectedItem: task }),
          },
          props: {
            model: "task",
            statusId: task?.statusId,
            loading: task?.isUpdatingStatus,
            disabled: task?.isUpdatingStatus,
          },
        }),
        this.$createElement("AppDefaultTextField", {
          props: {
            id: `itemTaskTextField_${task.id}`,
            value: task.name,
            hideDetails: true,
            autocomplete: "new-password",
            rules: this.$rules.NAME_RULES,
            backgroundColor: "transparent",
          },
          on: {
            blur: (e) => this.onTaskUpdate(e, "name", task),
            keydown: (e) => (e.key === "Enter" ? this.onTaskNameChangeEnter(task) : ""),
          },
        }),
      ]);
    },
    genMemberElm(task) {
      return this.$createElement(
        "div",
        { class: "item-task__element", style: "width: 110px;" },
        [
          this.$createElement(VMenu, {
            props: {
              offsetY: true,
              closeOnContentClick: false,
              transition: "scale-transition",
              maxWidth: "290px",
              minWidth: "auto",
            },
            scopedSlots: {
              activator: ({ on, attrs }) =>
                this.$createElement("div", { attrs, on, class: "d-flex align-center" }, [
                  task.member
                    ? [
                        this.$createElement(AppUserAvatarTooltip, {
                          props: { user: task.member, top: true },
                        }),
                      ]
                    : [
                        this.$createElement("AppAssignPlusIcon", {
                          props: {
                            text: this.$t("taskManagement.list.assign"),
                          },
                        }),
                      ],
                ]),
              default: () =>
                this.$createElement(VCard, { class: "pa-4" }, [
                  this.$createElement("AppClientGroupedUserAndContactAutocomplete", {
                    props: {
                      items: this.assignableUsers,
                      labelPaths: ["workTitleLabel", "contact.relation.label"],
                    },
                    attrs: {
                      value: task.member ? task.member.id : null,
                      userAvatar: true,
                      hideDetails: true,
                      autofocus: true,
                    },
                    on: { change: (e) => this.onTaskUpdate(e, "memberId", task) },
                  }),
                ]),
            },
          }),
        ],
      );
    },
    genDueDateElm(task) {
      return this.$createElement(
        "div",
        { class: "item-task__element", style: "width: 151px; min-width: 120px" },
        [
          this.$createElement(VMenu, {
            props: {
              offsetY: true,
              closeOnContentClick: false,
              transition: "scale-transition",
              maxWidth: "290px",
              minWidth: "auto",
            },
            scopedSlots: {
              activator: ({ on, attrs }) =>
                task.dueDate
                  ? this.$createElement(
                      "div",
                      { attrs, on },
                      this.formatDueDate(task.dueDate),
                    )
                  : this.$createElement(
                      "div",
                      {
                        attrs,
                        on,
                        class: "d-flex align-center",
                      },
                      [
                        this.$createElement("AppAssignPlusIcon", {
                          props: {
                            text: this.$t("taskManagement.list.addDueDate"),
                          },
                        }),
                      ],
                    ),
              default: () => [
                this.$createElement(VCard, [
                  this.$createElement("AppDefaultTextField", {
                    class: "px-4 pt-4",
                    props: {
                      value: this.formatDueDate(task.dueDate),
                      hideDetails: true,
                      readonly: true,
                      autocomplete: "false",
                      appendIcon: this.$icon.LIGHT.ACTION.CLEAR,
                    },
                    on: {
                      "click:append": (e) => this.onTaskUpdate(null, "dueDate", task),
                    },
                  }),
                  this.$createElement(VDatePicker, {
                    props: {
                      noTitle: true,
                      scrollable: true,
                      value: task.dueDateFormatted,
                      firstDayOfWeek: 1,
                      showWeek: true,
                      localeFirstDayOfYear: 4,
                      firstDayOfWeek: this.dateMixins_firstDayOfWeek,
                      color: "primary",
                    },
                    on: { change: (e) => this.onTaskUpdate(e, "dueDate", task) },
                  }),
                ]),
              ],
            },
          }),
        ],
      );
    },
    genTasks() {
      const elms = [];
      if (Array.isArray(this.item?.tasks)) {
        for (let task of this.item.tasks) {
          elms.push(
            this.$createElement("div", { class: "item-task" }, [
              this.genNameElm(task),
              this.genMemberElm(task),
              this.genDueDateElm(task),
              this.$createElement("div", { class: "item-task__element" }, [
                this.$createElement(VMenu, {
                  props: {
                    offsetY: true,
                  },
                  scopedSlots: {
                    activator: (props) =>
                      this.$createElement(VBtn, { ...props, props: { icon: true } }, [
                        this.$createElement(VIcon, this.$icon.REGULAR.ACTION.MENU),
                      ]),
                    default: () =>
                      this.$createElement(
                        "div",
                        { class: "d-flex flex-column pa-2 ui-background" },
                        [
                          this.$createElement("AppMenuOpenInNewTabBtn", {
                            props: {
                              type: "task",
                              id: task.id,
                            },
                          }),
                          this.$createElement("AppMenuDeleteBtn", {
                            on: {
                              click: (e) =>
                                this.$emit("item:taskDelete", { item: this.item, task }),
                            },
                          }),
                        ],
                      ),
                  },
                }),
              ]),
            ]),
          );
        }
      }
      return this.$createElement("div", { class: "item-tasks__container" }, elms);
    },
  },
  render(h) {
    return h("div", { class: "py-2 text-border-reset" }, [
      this.$createElement("div", { class: "pb-1" }, [
        h(
          "div",
          { class: "text-body-2 font-weight-bold" },
          this.$options.filters.capitalize(this.$t("common.tasks")),
        ),
      ]),
      this.genTasks(),
      this.$createElement("AppChangeCustomStatusIconMenu", {
        ref: "statusMenu",
        props: {
          model: "task",
        },
        on: {
          "status:change": this.onStatusChange,
        },
      }),
    ]);
  },
};
</script>
