import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;

export async function getFileContent(id, params) {
  return axios
    .get(`${apiUrl}storage/file/${id}/content`, {
      params,
      responseType: "blob",
    })
    .then(({ data }) => {
      const blobUrl = URL.createObjectURL(data);
      return blobUrl;
    })
    .catch((error) => {
      throw new Error(error);
    });
}
export async function uploadFiles({ formData }) {
  return axios.post(`${apiUrl}storage/file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
