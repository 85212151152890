<template>
  <div>
    <AppTimeEntryDialog
      v-model="dialog.updateTimeEntry.active"
      :title="$t('timeRegistration.newTimeEntry.editTitle')"
      :data="dialog.updateTimeEntry.data"
      @confirm="afterTimeEntryUpdate"
    />
    <AppDeleteConfirmationDialog
      v-model="dialog.deleteTimeEntry"
      :title="$tc('timeRegistration.deleteTimeEntry.title', selectedTimeEntries.length)"
      :subtitle="$t('timeRegistration.deleteTimeEntry.subTitle')"
      :validator="$t('timeRegistration.delete').toLowerCase()"
      :validatorText="
        $t('timeRegistration.deleteTimeEntry.validatorText', {
          delete: $t('common.delete').toLowerCase(),
        })
      "
      @delete="onTimeEntriesDelete"
    />
    <AppConfirmationTextareaDialog
      v-model="dialog.giveDeclineReason.active"
      :title="$t('timeRegistration.giveDeclineReason.title')"
      :textareaLabel="$t('timeRegistration.giveDeclineReason.label')"
      :data="dialog.giveDeclineReason.ids"
      @confirm="
        updateTimeEntries({
          body: {
            declineComment: $event.comment,
            status: $constant.TIME_ENTRY_STATUS.DECLINED,
          },
          ids: $event.data,
        })
      "
    />
    <AppTimeEntryDeclineReasonDialog
      v-model="dialog.seeDeclineReason.active"
      :timeEntry="dialog.seeDeclineReason.timeEntry"
    />
    <AppDataTableServerSidePagination
      v-model="localSelectedTimeEntries"
      :loading="isLoading"
      :isSelectingAll="isSelectingAll"
      :headers="_headers"
      :items="timeEntries"
      :noDataText="$t('common.noTimeEntries')"
      :search="search"
      :serverItemsLength="serverItemsLength"
      :tableModel="tableModel"
      :columnsToFreeze="columnsToFreeze"
      :groupHeaderColumnsToFreeze="2"
      @selectAll:click="$emit('selectAll:click')"
      show-select
    >
      <template v-slot:[`group.header`]="{ toggle, group, items, isOpen, headers }">
        <td @click="toggle">
          <v-simple-checkbox
            :indeterminate="isIndeterminate(items)"
            :value="isTimeEntriesSelected(items)"
            @input="toggleTimeEntries(items)"
          />
        </td>
        <td @click="toggle" :colspan="headers.length - 9">
          <div class="d-flex align-center">
            <v-btn class="mr-4" icon small @click.stop="toggle">
              <v-icon>
                {{
                  isOpen ? $icon.LIGHT.COMMON.CHEVRON_DOWN : $icon.LIGHT.COMMON.CHEVRON_UP
                }}
              </v-icon>
            </v-btn>
            <div
              class="group-header__title"
              :class="groupByMixins_headerTitleColor(group)"
            >
              {{ headerTitle(group) }}
              <v-icon
                v-if="groupByMixins_isGroupSelected(filtersMixins_dataFields.STATUS)"
                :color="headerIconColor(group)"
                class="pl-3"
              >
                {{ $icon.SOLID.ACTION.STATUS }}
              </v-icon>
            </div>
          </div>
        </td>

        <td colspan="4" @click="toggle"></td>
        <td @click="toggle">
          <div class="text-end subtitle--text text-no-wrap">
            <span class="font-weight-bold">
              {{ $t("timeRegistration.common.total") | capitalize }}
            </span>
          </div>
        </td>

        <td @click="toggle">
          <div class="text-end subtitle--text text-no-wrap">
            {{
              timeMixins_formatHoursShort(
                timeMixins_aggregateDuration(
                  items?.filter((item) => !item.isExtra),
                  filtersMixins_dataFields.DURATION,
                ),
              )
            }}
          </div>
        </td>
        <td @click="toggle">
          <div class="text-end subtitle--text text-no-wrap">
            {{
              timeMixins_formatHoursShort(
                timeMixins_aggregateDuration(
                  items,
                  filtersMixins_dataFields.BREAK_DURATION,
                ),
              )
            }}
          </div>
        </td>
        <td @click="toggle"></td>
      </template>
      <template v-slot:item="{ item, isSelected, select, headers }">
        <AppTimeEntryItem
          :timeEntry="item"
          :isSelected="isSelected"
          :headers="headers"
          :tableModel="tableModel"
          @isSelected:change="select"
          @timeEntry:update="onTimeEntryUpdate"
          @status:update="onStatusChange"
          @menu:click="onMenuClick"
          @status:click="onStatusClick"
          @imagePreview:click="onImagePreviewClick"
        />
      </template>
    </AppDataTableServerSidePagination>
    <AppContextMenu ref="contextMenu">
      <AppMenuEditBtn :disabled="isEditDisabled" @click="onTimeEntryEdit" />
      <AppContextMoreMenu
        right
        offset-x
        :text="$t('common.status')"
        :icon="$icon.REGULAR.COMMON.SQUARE"
        :disabled="isChangeStatusesDisabled(localSelectedTimeEntries, isMultipleSelected)"
      >
        <AppChangeStatusMenuItems
          :statuses="statusMixins_timeEntriesStatusItems"
          :itemsDisabled="
            isChangeStatusesItemsDisabled(localSelectedTimeEntries, isMultipleSelected)
          "
          @change="onStatusesChange({ status: $event })"
        />
      </AppContextMoreMenu>
      <AppMenuSendToAccountingBtn
        v-if="showSendToAccounting"
        :disabled="isSendToAccountingDisabled"
        :showStar="!planRestrictionsMixins_canIntegrateAccounting"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        @click="onSendToAccounting"
      />

      <AppMenuItem
        v-if="isSomeSelectedItemsDeclined"
        :disabled="isDeclineReasonDisabled"
        :text="$t('timeRegistration.contextMenu.declinedReason')"
        :icon="$icon.REGULAR.ACTION.WARNING"
        @click="onShowDeclinedReason"
      />

      <v-divider class="my-1" />
      <AppMenuItem
        v-if="isSomeSelectedItemsInAccounting && isSuperUser"
        :disabled="isDeleteInAccountingDisabled"
        :showStar="!planRestrictionsMixins_canIntegrateAccounting"
        :planCode="$constant.PLAN_CODE.GRIPR_PRO"
        :text="$t('timeRegistration.deleteInAccounting.title')"
        :icon="$icon.REGULAR.ACTION.RESET_TO_LAST"
        color="error"
        @click="deleteInAccounting"
      />
      <AppMenuDeleteBtn :disabled="isDeleteDisabled" @click="onTimeEntryDelete" />
    </AppContextMenu>

    <AppContextMenu ref="statusMenu">
      <AppChangeStatusMenuItems
        :status="statusMenu.selectedTimeEntry?.status"
        :statuses="statusMixins_timeEntriesStatusItems"
        :itemsDisabled="isChangeStatusItemsDisabled(statusMenu.selectedTimeEntry, false)"
        @change="changeStatusFromContextMenu"
      />
    </AppContextMenu>

    <AppFilePreviewDialog
      v-model="dialog.filePreview.fileId"
      :fileIds="dialog.filePreview.fileIds"
      @dialog:close="onImagePreviewDialogClose"
      :canEditFile="false"
    />
  </div>
</template>

<script>
import {
  timeMixins,
  planRestrictionsMixins,
  statusMixins,
  groupByMixins,
} from "@/helpers/mixins";
import { mapGetters } from "vuex";

export default {
  mixins: [timeMixins, planRestrictionsMixins, statusMixins, groupByMixins],
  props: {
    timeEntries: Array,
    isLoading: Boolean,
    isSelectingAll: Boolean,
    selectedTimeEntries: Array,
    search: String,
    serverItemsLength: Number,
    tableModel: String,
  },
  model: {
    prop: "selectedTimeEntries",
    event: "selectedTimeEntries:change",
  },
  data() {
    return {
      dialog: {
        updateTimeEntry: {
          data: null,
          active: false,
        },
        deleteTimeEntry: false,
        giveDeclineReason: {
          active: false,
          ids: null,
        },
        seeDeclineReason: {
          active: false,
          timeEntry: null,
        },
        upgradePlan: {
          active: false,
          planCode: null,
        },
        filePreview: {
          fileId: null,
          fileIds: [],
        },
      },
      statusMenu: {
        selectedTimeEntry: null,
      },
    };
  },
  computed: {
    localSelectedTimeEntries: {
      get() {
        return this.selectedTimeEntries;
      },
      set(value) {
        this.$emit("selectedTimeEntries:change", value);
      },
    },
    ...mapGetters("activeIntegrations", {
      hasTimeEntriesIntegrationEnabled: "hasTimeEntriesIntegrationEnabled",
    }),
    ...mapGetters("timeEntries", {
      timeEntryById: "timeEntryById",
    }),
    ...mapGetters("auth", {
      currentUserId: "currentUserId",
      isDomainOwner: "isDomainOwner",
      isDomainAdmin: "isDomainAdmin",
      isDomainHrAdmin: "isDomainHrAdmin",
      isDomainSupportUser: "isDomainSupportUser",
    }),
    columnsToFreeze() {
      const groupableDataFields = [
        this.filtersMixins_dataFields.STATUS,
        this.filtersMixins_dataFields.DATE,
        this.filtersMixins_dataFields.USER,
      ];

      let count = 4;

      groupableDataFields.forEach((field) => {
        if (this.filtersMixins_dataTableOptions.groupBy?.includes(field)) {
          count -= 1;
        }
      });

      return count;
    },
    isDeleteDisabled() {
      if (this.isSomeSelectedItemsInAccounting) {
        return true;
      }
      if (this.isSuperUser) {
        return false;
      }
      if (this.isOwnerOfAllSelectedItems) {
        return false;
      }
      return true;
    },
    isDeleteInAccountingDisabled() {
      if (this.isAllSelectedItemsInAccounting && this.isSuperUser) {
        return false;
      }
      return true;
    },
    showSendToAccounting() {
      return (
        this.hasTimeEntriesIntegrationEnabled &&
        this.isSuperUser &&
        this.planRestrictionsMixins_canIntegrateAccounting
      );
    },
    isSendToAccountingDisabled() {
      if (this.isAllSelectedItemsInAccounting) {
        return true;
      }
      return false;
    },
    isDeclineReasonDisabled() {
      if (this.isMultipleSelected) return true;
      if (this.isAllSelectedItemsDeclined) return false;
      return true;
    },
    isEditDisabled() {
      if (
        this.isMultipleSelected ||
        this.isSomeSelectedItemsInAccounting ||
        this.isSomeSelectedItemsApproved
      ) {
        return true;
      }
      if (
        this.isSuperUser ||
        this.isOwnerOfProjectOfAllSelectedItems ||
        this.isOwnerOfAllSelectedItems
      ) {
        return false;
      }
      return true;
    },
    isOwnerOfAllSelectedItems() {
      return this.localSelectedTimeEntries.every((te) => {
        return te.userId === this.currentUserId;
      });
    },
    isOwnerOfProjectOfAllSelectedItems() {
      return this.localSelectedTimeEntries.every(
        (te) => te.project?.ownerId === this.currentUserId,
      );
    },
    isAllSelectedItemsApproved() {
      return this.localSelectedTimeEntries.every(
        (te) => te.status === this.$constant.TIME_ENTRY_STATUS.APPROVED,
      );
    },
    isAllSelectedItemsDeclined() {
      return this.localSelectedTimeEntries.every(
        (te) => te.status === this.$constant.TIME_ENTRY_STATUS.DECLINED,
      );
    },
    isAllSelectedItemsInAccounting() {
      return this.localSelectedTimeEntries.every((item) => !!item.transferred);
    },
    isSomeSelectedItemsDeclined() {
      return this.localSelectedTimeEntries.some(
        (te) => te.status === this.$constant.TIME_ENTRY_STATUS.DECLINED,
      );
    },
    isSomeSelectedItemsApproved() {
      return this.localSelectedTimeEntries.some(
        (te) => te.status === this.$constant.TIME_ENTRY_STATUS.APPROVED,
      );
    },
    isSomeSelectedItemsInAccounting() {
      return this.localSelectedTimeEntries.some((te) => !!te.transferred);
    },
    isSuperUser() {
      return (
        this.isDomainOwner ||
        this.isDomainAdmin ||
        this.isDomainHrAdmin ||
        this.isDomainSupportUser
      );
    },
    isMultipleSelected() {
      return this.localSelectedTimeEntries.length > 1;
    },
    selectedTimeEntriesIds() {
      return this.localSelectedTimeEntries.map((te) => te.id);
    },
    selectedTimeEntriesIdsNotInAccounting() {
      return this.localSelectedTimeEntries
        .filter((te) => !te.transferred)
        .map((te) => te.id);
    },
    hasMappedHourTypes() {
      return !!this.localSelectedTimeEntries.every((te) => !!te.hourType.externalCode);
    },
    _headers() {
      return this.headers.filter((header) => header.show);
    },
    headers() {
      return [
        {
          value: this.filtersMixins_dataFields.STATUS,
          sortable: false,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.date"),
          ),
          value: this.filtersMixins_dataFields.DATE,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.user"),
          ),
          value: this.filtersMixins_dataFields.USER,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.comment"),
          ),
          value: this.filtersMixins_dataFields.COMMENT,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.images"),
          ),
          value: this.filtersMixins_dataFields.IMAGE_IDS,
          show: true,
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.project"),
          ),
          value: this.filtersMixins_dataFields.PROJECT,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.task"),
          ),
          value: this.filtersMixins_dataFields.TASK,
          show: true,
        },
        {
          value: this.filtersMixins_dataFields.TRANSFERRED,
          show: this.hasTimeEntriesIntegrationEnabled,
          sortable: false,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.hourType"),
          ),
          value: this.filtersMixins_dataFields.HOUR_TYPE,
          sortable: true,
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.time"),
          ),
          value: this.filtersMixins_dataFields.TIME,
          align: "end",
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.hours"),
          ),
          value: this.filtersMixins_dataFields.DURATION,
          align: "end",
          show: true,
        },
        {
          text: this.$options.filters.capitalize(
            this.$t("timeRegistration.allHours.table.break"),
          ),
          value: this.filtersMixins_dataFields.BREAK_DURATION,
          align: "end",
          show: true,
        },
        {
          text: "",
          sortable: false,
          show: true,
        },
      ];
    },
  },
  methods: {
    isTimeEntryOwner(timeEntry) {
      return timeEntry.userId === this.currentUserId;
    },
    isChangeStatusItemsDisabled(timeEntry, isMultipleSelected) {
      if (!timeEntry) return false;
      const isDisabled = this.statusMixins_timeEntriesStatusItems.reduce(
        (result, statusItem) => {
          result[statusItem.value] = true;
          return result;
        },
        {},
      );

      const setIsDisabled = (value) => {
        for (const statusItem of this.statusMixins_timeEntriesStatusItems) {
          isDisabled[statusItem.value] = value;
        }
      };

      const hasSpecialRole =
        this.isDomainOwner ||
        this.isDomainAdmin ||
        this.isDomainHrAdmin ||
        this.isDomainSupportUser;

      const isProjectOwner = timeEntry.project?.ownerId === this.currentUserId;

      //logic for roles
      if (hasSpecialRole) {
        //Enable all for super users
        setIsDisabled(false);
      } else if (isProjectOwner) {
        //Project manager can manage time entries for entries on a project except their own
        const isTimeEntryOwner = this.isTimeEntryOwner(timeEntry);
        setIsDisabled(isTimeEntryOwner);
      } else {
        //disable for everyone else
        setIsDisabled(true);
      }

      // Logic for all users regardless of role
      if (isMultipleSelected) {
        isDisabled.DECLINED = true;
      }
      if (timeEntry.transferred) {
        // Entries in accounting cant be edited
        setIsDisabled(true);
      }
      // for (const statusItem of this.statusMixins_timeEntriesStatusItems) {
      //   if (timeEntry.status === statusItem.value) {
      //     isDisabled[statusItem.value] = true;
      //     break;
      //   }
      // }

      return isDisabled;
    },
    isChangeStatusesItemsDisabled(timeEntries, isMultipleSelected) {
      const isDisabledStatuses = this.statusMixins_timeEntriesStatusItems.reduce(
        (result, action) => {
          result[action.value] = timeEntries.some(
            (timeEntry) =>
              this.isChangeStatusItemsDisabled(timeEntry, isMultipleSelected)[
                action.value
              ],
          );
          return result;
        },
        {},
      );

      return isDisabledStatuses;
    },
    isChangeStatusesDisabled(timeEntries, isMultipleSelected) {
      const statusObject = this.isChangeStatusesItemsDisabled(
        timeEntries,
        isMultipleSelected,
      );
      return Object.values(statusObject).every((isDisabled) => isDisabled);
    },
    headerIconColor(group) {
      if (group === this.$constant.TIME_ENTRY_STATUS.PENDING) {
        return "warning";
      }
      if (group === this.$constant.TIME_ENTRY_STATUS.APPROVED) {
        return "success";
      }
      if (group === this.$constant.TIME_ENTRY_STATUS.DECLINED) {
        return "error";
      }
      return "";
    },
    onSendToAccounting() {
      if (!this.planRestrictionsMixins_canIntegrateAccounting) {
        this.dialog.upgradePlan.active = true;
        return;
      }

      if (!this.hasMappedHourTypes) {
        this.handleUnmappedHourTypes();
        return;
      }

      if (!this.isAllSelectedItemsApproved) {
        this.showSnackbar(
          "timeRegistration.accounting.snackbar.onlyApprovedTimeEntries",
          "warning",
        );
        return;
      }

      this.$store.dispatch("timeEntries/sendToAccounting", {
        ids: this.selectedTimeEntriesIdsNotInAccounting,
      });
    },
    handleUnmappedHourTypes() {
      const canEnterSettingsPage =
        this.isDomainAdmin || this.isDomainOwner || this.isDomainSupportUser;

      if (canEnterSettingsPage) {
        const route = this.$router.resolve({
          name: this.$routeNames.SETTINGS.SYSTEM_SETTINGS,
        });
        window.open(route.href, "_blank");
      } else {
        this.showSnackbar(
          "timeRegistration.accounting.snackbar.hrAdminCantConfigure",
          "error",
        );
      }
    },
    showSnackbar(messageKey, color) {
      this.$store.dispatch("snackbar/snackbar", {
        show: true,
        text: this.$t(messageKey),
        color,
      });
    },
    deleteInAccounting() {
      if (!this.planRestrictionsMixins_canIntegrateAccounting) {
        this.dialog.upgradePlan.active = true;
        return;
      }
      this.$store.dispatch("timeEntries/deleteInAccounting", {
        ids: this.selectedTimeEntriesIds,
      });
    },
    onStatusChange({ status, timeEntry }) {
      if (status === "DECLINED") {
        this.dialog.giveDeclineReason.active = true;
        this.dialog.giveDeclineReason.ids = [timeEntry.id];
        return;
      }
      this.updateTimeEntries({ body: { status }, ids: [timeEntry.id] });
    },
    onStatusesChange({ status, timeEntries = [] }) {
      const ids =
        timeEntries.length > 0
          ? timeEntries.map((te) => te.id)
          : this.selectedTimeEntriesIds;

      if (status === "DECLINED") {
        this.dialog.giveDeclineReason.active = true;
        this.dialog.giveDeclineReason.ids = ids;
        return;
      }
      this.updateTimeEntries({ body: { status }, ids });
    },
    changeStatusFromContextMenu(e) {
      this.onStatusesChange({
        status: e,
        timeEntries: [this.statusMenu.selectedTimeEntry],
      });
    },
    updateTimeEntries({ body, ids }) {
      this.$store.dispatch("timeEntries/updateTimeEntries", {
        ids,
        body,
      });
    },
    onTimeEntriesDelete() {
      this.$store
        .dispatch("timeEntries/deleteTimeEntries", {
          ids: this.selectedTimeEntriesIds,
        })
        .then(() => {
          this.localSelectedTimeEntries = [];
        });
    },
    isIndeterminate(groupItems) {
      return (
        !this.isTimeEntriesSelected(groupItems) &&
        this.isSomeTimeEntriesSelected(groupItems)
      );
    },
    isTimeEntriesSelected(groupItems) {
      return groupItems.every((item) => this.isItemSelected(item.id));
    },
    isSomeTimeEntriesSelected(groupItems) {
      return groupItems.some((item) =>
        this.localSelectedTimeEntries.some((selectedItem) => selectedItem.id === item.id),
      );
    },
    isItemSelected(itemId) {
      return this.localSelectedTimeEntries.some((item) => item.id === itemId);
    },
    toggleTimeEntries(timeEntries) {
      if (this.isTimeEntriesSelected(timeEntries)) {
        this.removeTimeEntries(timeEntries);
      } else {
        this.selectTimeEntries(timeEntries);
      }
    },
    selectTimeEntries(timeEntries) {
      const timeEntriesToAdd = timeEntries.filter((te) => !this.isItemSelected(te.id));

      this.localSelectedTimeEntries = [
        ...this.localSelectedTimeEntries,
        ...timeEntriesToAdd,
      ];
    },
    removeTimeEntries(timeEntries) {
      const timeEntriesToRemove = timeEntries.filter((te) => this.isItemSelected(te.id));

      this.localSelectedTimeEntries = this.localSelectedTimeEntries.filter(
        (te) => !timeEntriesToRemove.some((t) => t.id === te.id),
      );
    },
    headerTitle(group) {
      const commonHeaderTitle = this.groupByMixins_commonHeaderTitle(group);
      if (commonHeaderTitle) return commonHeaderTitle;
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.STATUS))
        return this.$t(`statuses.timeEntry.${group}`);
      if (this.groupByMixins_isGroupSelected(this.filtersMixins_dataFields.TRANSFERRED))
        return this.$t(
          `timeRegistration.accounting.${group ? "inAccounting" : "notInAccounting"}`,
        );
      return group;
    },
    onMenuClick({ event, timeEntry }) {
      if (!this.localSelectedTimeEntries.some((t) => t.id === timeEntry.id)) {
        this.localSelectedTimeEntries = [timeEntry];
      }

      this.$refs.contextMenu.open({ pointerEvent: event });
    },
    onTimeEntryDelete() {
      this.dialog.deleteTimeEntry = true;
    },
    onTimeEntryEdit() {
      this.dialog.updateTimeEntry.active = true;
      this.dialog.updateTimeEntry.data = this.localSelectedTimeEntries[0];
    },
    onTimeEntryOpen() {
      this.$router.push({
        name: "timeEntryView",
        params: { timeEntryId: this.selectedItem.id },
      });
    },
    onShowDeclinedReason() {
      const timeEntry = this.localSelectedTimeEntries[0];
      if (!timeEntry) return;

      this.dialog.seeDeclineReason.active = true;
      this.dialog.seeDeclineReason.timeEntry = timeEntry;
    },
    onTimeEntryUpdate({ body, timeEntry }) {
      this.$emit("timeEntry:update", { data: body, item: timeEntry });
    },
    afterTimeEntryUpdate(e) {
      this.$emit("timeEntry:afterUpdate", e);
    },
    onStatusClick({ x, y, pointerEvent, timeEntry }) {
      if (!timeEntry || timeEntry?.isUpdatingStatus) return;

      this.$refs.statusMenu.open({ x, y, pointerEvent });
      this.statusMenu.selectedTimeEntry = timeEntry;
    },
    onImagePreviewClick({ timeEntry }) {
      const fileIds = timeEntry.imageIds;
      const fileId = fileIds[0];
      if (!fileId) return;
      this.dialog.filePreview.fileId = fileId;
      this.dialog.filePreview.fileIds = fileIds.map((id) => ({ id }));
    },
    onImagePreviewDialogClose() {
      this.dialog.filePreview.fileId = null;
      this.dialog.filePreview.fileIds = [];
    },
  },
};
</script>
